/* eslint-disable array-callback-return */
import { CommonDialog } from '@ifca-ui/neumorphism'
import { Avatar, Button, ListItemAvatar } from '@material-ui/core'
import ExtendStayIcon from 'assets/svg/service/extend-stay.svg'
import dateFormat from 'dateformat'
import { useCheckOutMutation } from 'generated/graphql'
import React from 'react'
import { useHistory } from 'react-router'
import { SERVICE_PATH } from './Routes'
import QRCode from 'qrcode.react'

export const PasscodeDialog = ({
  state,
  Passcode,
  dispatch,
}) => {
  const history = useHistory()
 

  return (
    <CommonDialog
      open={state.PasscodeDialog}
      sections={{
        content: (
          <>

           <div className=" text-align-center color-text mdDesc m-b-10">
              MyQR
            </div>
            <div className=" text-align-center color-text mdDesc m-b-10">
            <QRCode
        renderAs={'canvas'}
        value={`${state.Department?.HotelID}`}
      />
        </div>
            <div className=" text-align-center color-text mdDesc m-b-10">
              PassCode
            </div>
            <div
              className="m-b-10"
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
      
              <div
                style={{
                  margin: 'auto 6px',
                }}
              >
               
                <div className="  room-no shadow fs-30 fw-bold">
                  {Passcode?.keyboardPwd}
                </div>
              </div>
            </div>
            <div className=" text-align-center color-text-2 fs-12 fw-regular m-b-10">
              {dateFormat(new Date(), 'dd mmm yyyy hh:MM TT')}
            </div>
          </>
        ),
        footer: {
          children: (
            <div  style={{margin:'0 auto'}}>
              <Button
                variant="contained"
                color="primary"
                size="small"
                className="action-btn"
                onClick={() => {
                  dispatch({
                    type: 'PasscodeDialog',
                    payload: false,
                  })
                }}
              >
                Close
              </Button>
            </div>
          ),
        },
      }}
    />
  )
}
