/* eslint-disable react-hooks/exhaustive-deps */
import {
  ContentWrapper,
  Spinner,
  StyledCardContent,
  StyledFooter,
  CommonDialog,
} from '@ifca-ui/neumorphism'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Button,
  Grid,
  IconButton,
  Popover,
  TextareaAutosize,
  TextField,
  Checkbox,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Autocomplete } from '@material-ui/lab'
import DefaultImg from 'assets/images/default-img.png'
import EditIcon from 'assets/svg/edit.svg'
import MoreIcon from 'assets/svg/more.svg'
import PersonIcon from 'assets/svg/person.svg'
import RemoveIcon from 'assets/svg/remove.svg'
import DurationIcon from 'assets/svg/stay-duration.svg'
import BigNumber from 'bignumber.js'
import { RedirectUrl } from 'containers/App/Client'
import AppContext, { AppContextProps } from 'containers/Context/Context'
import { IAction } from 'containers/Context/Types'
import { DialogMode } from 'containers/Utils/CommonTypes'
import { NationalityEnum } from 'containers/Utils/Enum'
import { amtStr } from 'containers/Utils/numFormatter'
import format from 'date-fns/format'
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import {
  BookingStatus,
  Nationality,
  Source,
  TaxIdentifier,
  useComputeTaxArrByHotelLazyQuery,
  useComputeTaxbyHotelLazyQuery,
  useCurrentTaxDetailsQuery,
  useDisCountByPromoCodeLazyQuery,
  useLoggedInGuestProfileQuery,
  useOnlineBookingInsertMutation,
  useOnlinePaymentMutation,
  useSegmentListingQuery,
} from 'generated/graphql'
import React, { FC, Reducer, useContext, useEffect, useReducer,useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import { BOOKING_PATH } from '../Routes'
import { BookingSummaryDialog } from './BookingSummaryDialog'
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import { TaxesFeeDialog } from './TaxesFeeDialog'
import { differenceInDays } from 'date-fns'
import * as _ from 'underscore'

interface Props {
  RoomTypeSelected: any
  PromoCode?: {
    Error?: boolean
    HelperText?: string
    Value?: string
  }
  Contact?: {
    FullName?: string
    Nationality?: string
    MobileNo?: string
    Email?: string
  }
  GuestInfo?: {
    ID?: string | null
    GuestID?: string | null
    FullName?: string
    Nationality?: string
    MobileNo?: string
    Email?: string
  }
  Dialog?: {
    Open?: boolean
    Mode?: DialogMode
  }
  TaxesDialog?: boolean
  Expanded?: boolean | number
  AnchorEl: null | undefined | HTMLElement
  IndexSelected: null | boolean
  GuestSelected: null | boolean
  TnCDialog: boolean
}
export const BookingSummary: FC = () => {
  const location = useLocation()
  const localState = location.state as any
  const {
    loading: infoLoading,
    data: { loggedInGuestProfile } = { loggedInGuestProfile: null },
  } = useLoggedInGuestProfileQuery({
    fetchPolicy: 'network-only',
  })

  const [
    ComputeTaxArrByHotelQuery,
    { data: { ComputeTaxArrByHotel } = { ComputeTaxArrByHotel: [] } },
  ] = useComputeTaxArrByHotelLazyQuery({
    fetchPolicy: 'no-cache',
  })
  const [
    ComputeTax,
    { data: { ComputeTaxbyHotel } = { ComputeTaxbyHotel: null } },
  ] = useComputeTaxbyHotelLazyQuery({
    fetchPolicy: 'no-cache',
  })
  const {
    data: { SegmentListing } = { SegmentListing: [] },
  } = useSegmentListingQuery({
    variables: {
      HotelID: localState.HotelSelected?.ID,
    },
    fetchPolicy: 'no-cache',
  })
  const {
    data: { CurrentTaxDetails } = { CurrentTaxDetails: null },
  } = useCurrentTaxDetailsQuery({
    variables: {
      HotelID: localState.HotelSelected?.ID,
    },
  })

  const { RootState, RootDispatch } = useContext<AppContextProps>(AppContext)

  const history = useHistory()

  const [check, setCheck] = useState(false)
  const initialState: Props = {
    RoomTypeSelected: localState.RoomTypeSelected,
    PromoCode: {
      Error: false,
      HelperText: '',
      Value: '',
    },
    Contact: {
      FullName: '',
      Nationality: 'Malaysian',
      MobileNo: '',
      Email: '',
    },
    GuestInfo: {
      ID: '',
      GuestID: '',
      FullName: '',
      Nationality: 'Malaysian',
      MobileNo: '',
      Email: '',
    },
    Dialog: {
      Open: false,
      Mode: '',
    },
    AnchorEl: null,
    IndexSelected: null,
    GuestSelected: null,
    Expanded: 0,
    TaxesDialog: false,
    TnCDialog: false,
  }

  const reducer: Reducer<Props, IAction> = (state, action) => {
    switch (action.type) {
      case 'AddDialog':
        return { ...state, Dialog: { Open: true, Mode: 'Add' }, AnchorEl: null }
      case 'UpdateDialog':
        return {
          ...state,
          Dialog: { Open: true, Mode: 'Edit' },
          AnchorEl: null,
        }
      case 'PaymentDialog':
        return {
          ...state,
          Dialog: { Open: true, Mode: 'Payment' },
          AnchorEl: null,
        }
      case 'CloseTaxesDialog':
        return {
          ...state,
          TaxesDialog: false,
        }
      case 'CloseDialog':
        return {
          ...state,
          Dialog: { Open: false, Mode: '' },
          GuestInfo: {
            ID: '',
            GuestID: '',
            FullName: '',
            Nationality: 'Malaysian',
            MobileNo: '',
            Email: '',
          },
        }
      case 'Reset':
        return initialState
      default:
        return { ...state, [action.type]: action.payload }
    }
  }
  const [state, dispatch] = useReducer(reducer, initialState)
  let dateFormat = require('dateformat')

  let NoOfDays = differenceInDays(
    new Date(dateFormat(localState.CheckOutDate, 'yyyy-mm-dd')),
    new Date(dateFormat(localState.CheckInDate, 'yyyy-mm-dd'))
  )
  NoOfDays <= 0 && (NoOfDays = 1)

  console.log(NoOfDays)
  const Open = Boolean(state.AnchorEl)
  const ID = Open ? 'simple-popover' : undefined
  console.log(state, 'here')
  useEffect(() => {
    RootDispatch({
      type: 'HeaderSection',
      payload: {
        left: {
          icon: 'leftArrow',
          title: 'Booking Summary',
          props: {
            onClick: () =>
              history.push(BOOKING_PATH.HOTEL_DETAIL, { ...localState }),
          },
        },
      },
    })
  }, [])
  //! set Contact info for guest loggedin
  useEffect(() => {
    if (loggedInGuestProfile) {
      dispatch({
        type: 'Contact',
        payload: {
          FullName: loggedInGuestProfile?.Contact?.FullName,
          Nationality: loggedInGuestProfile?.Contact?.Nationality,
          MobileNo: loggedInGuestProfile?.Contact?.MobileNo,
          Email: loggedInGuestProfile?.Contact?.Email,
        },
      })
    }
  }, [loggedInGuestProfile])
  useEffect(() => {
    //! Set first room type with guest info and with name only
    const results = state.RoomTypeSelected.map((x: any, i: number) => ({
      ...x,
      Guests: [
        {
          ID: loggedInGuestProfile?.Contact?.ID || null,
          GuestID:  loggedInGuestProfile?.ID || null,
          FullName: loggedInGuestProfile?.Contact?.FullName || null,
          Nationality:
             loggedInGuestProfile?.Contact?.Nationality || null,
          MobileNo: loggedInGuestProfile?.Contact?.MobileNo || null,
          Email:  loggedInGuestProfile?.Contact?.Email || null,
        },
      ],
    }))
    dispatch({
      type: 'RoomTypeSelected',
      payload: results,
    })
  }, [loggedInGuestProfile])
  useEffect(() => {
    ComputeTax({
      variables: {
        input: {
          TaxIdentifier: TaxIdentifier['Room'],
          HotelID: localState?.HotelSelected?.ID,
          DiscountAmount: state.RoomTypeSelected?.reduce((acc, curr) => {
            return acc + curr.DiscountAmount
          }, 0),
          TrxAmount: state.RoomTypeSelected?.reduce((acc, curr) => {
            return (
              acc +
              curr.RatePackages?.Price *
                // curr.RatePackages?.TotalNumOfRoom *
                NoOfDays
            )
          }, 0),
        },
      },
    })
    // ComputeTaxArrByHotelQuery({
    //   variables: {
    //     TaxInput: state.RoomTypeSelected?.map(el => ({
    //       HotelID: localState.HotelSelected?.ID,
    //       TaxIdentifier: TaxIdentifier['Room'],
    //       // Tax: true,
    //       // ServiceCharge: true,
    //       DiscountAmount: el.OriDiscountAmount,
    //       TrxAmount:
    //         el.RatePackages?.Price * el.RatePackages?.TotalNumOfRoom * NoOfDays,
    //     })),
    //   },
    // })
  }, [state.RoomTypeSelected])
  const handleExpand = (panel: number) => (
    event: React.ChangeEvent<{}>,
    isExpanded: boolean
  ) => {
    event.stopPropagation()
    dispatch({ type: 'Expanded', payload: isExpanded ? panel : false })
  }
  const IsPostPaid = localState.HotelSelected?.IsPostPaid
  const SegmentID = SegmentListing?.find(x => x.Description === 'Alliance')?.ID
  const DisabledFooterBtn = state.RoomTypeSelected?.length === 0
  const FooterLabel = !IsPostPaid ? 'Make Payment' : 'Confirm Booking'
  const ServiceCharge = CurrentTaxDetails?.ServiceCharge
  const Tax = CurrentTaxDetails?.GovTax
  const ServiceChargeAmount = ComputeTaxbyHotel?.ServiceCharge
  //  ComputeTaxArrByHotel.reduce(
  //   (acc: BigNumber, curr): BigNumber => {
  //     let result: BigNumber
  //     result = acc.plus(curr.ServiceCharge as any)
  //     return result
  //   },
  //   new BigNumber(0)
  // )
  const TaxAmount = ComputeTaxbyHotel?.TaxAmount
  // ComputeTaxArrByHotel.reduce(
  //   (acc: BigNumber, curr): BigNumber => {
  //     let result: BigNumber
  //     result = acc.plus(curr.TaxAmount as any)
  //     return result
  //   },
  //   new BigNumber(0)
  // )
  const DiscountAmount = ComputeTaxbyHotel?.DiscountAmount
  //  state.RoomTypeSelected?.reduce(
  //   (acc: BigNumber, curr): BigNumber => {
  //     let result: BigNumber
  //     result = acc.plus(curr.DiscountAmount as any)
  //     return result
  //   },
  //   new BigNumber(0)
  // )
  const TotalAmount = ComputeTaxbyHotel?.TotalAmount
  // ComputeTaxArrByHotel.reduce((acc: BigNumber, curr): BigNumber => {
  //   let result: BigNumber
  //   result = acc.plus(curr.TotalAmount as any)
  //   return result
  // }, new BigNumber(0))
  const TotalTaxesFeeAmount = ServiceChargeAmount + TaxAmount

  // const TotalDiscountAmount = new BigNumber(TotalAmount)
  const [DisCountByPromoCodeQuery] = useDisCountByPromoCodeLazyQuery({
    fetchPolicy: 'no-cache',

    onError: error => {
      // eslint-disable-next-line array-callback-return
      error.graphQLErrors.map(({ message }) => {
        console.log(message)
        dispatch({
          type: 'PromoCode',
          payload: {
            ...state.PromoCode,
            Error: true,
            HelperText: message,
          },
        })
      })
    },
    onCompleted: data => {
      if (data.DisCountByPromoCode) {
        const results = state.RoomTypeSelected?.map(x => ({
          ...x,
          DiscountAmount:
            data.DisCountByPromoCode?.find(
              d =>
                d.RatePolicyID === x.RatePackages.RatePolicyID &&
                d.RoomTypeID === x.ID
            )?.DiscountAmt * NoOfDays || 0,
          OriDiscountAmount:
            data.DisCountByPromoCode?.find(
              d =>
                d.RatePolicyID === x.RatePackages.RatePolicyID &&
                d.RoomTypeID === x.ID
            )?.DiscountAmt || 0,
          PromoCode: data.DisCountByPromoCode?.find(
            d =>
              d.RatePolicyID === x.RatePackages?.RatePolicyID &&
              d.RoomTypeID === x.ID
          )?.RatePolicyID
            ? state.PromoCode?.Value
            : null,
        }))
        dispatch({
          type: 'RoomTypeSelected',
          payload: results,
        })
      }
    },
  })
  console.log(IsPostPaid,'IsPostPaid')
  const CheckRoomListWithoutGuest = state.RoomTypeSelected?.some(
    x => (x.Guests as any)?.length === 0
  )
  // const CheckRoomListWithoutGuestInfo = state.RoomTypeSelected?.some(x =>
  //   x.Guests?.some(el => !el.MobileNo || !el.Email)
  // )
  console.log(localState,'localState')
  const [
    OnlineBookingInsert,
    { loading: bookLoading },
  ] = useOnlineBookingInsertMutation({
    variables: {
      AppName: 'loyalty-app',
      BookingInput: {
        AccountID: localState?.HotelSelected?.AccountID,
        HotelID: localState?.HotelSelected?.ID,
        ArrivalDate: localState?.CheckInDate,
        DepartureDate: localState?.CheckOutDate,
        ContactID: loggedInGuestProfile?.Contact?.ID,
        SegmentID: SegmentID,
        Source: Source['Alliance'],
        SpecialRequest:
          state.RoomTypeSelected[0]?.SpecialReqDisCountByPromoCodeuest || null,
        GroupName:''
      },
      ContactInput: {
        ID: loggedInGuestProfile?.ContactID,
        MobileNo: state?.Contact?.MobileNo,
        FullName: state?.Contact?.FullName,
        Email: state?.Contact?.Email,
        Nationality: Nationality[state?.Contact?.Nationality],
        // GuestID: loggedInGuestProfile?.ID,
      },
      RegistrationInput: state.RoomTypeSelected?.map((x: any) => ({
        RoomTypeID: x.ID,
        RatePolicyID: x.RatePackages?.RatePolicyID,
        // CheckInDate: localState?.CheckInDate,
        // CheckOutDate: localState?.CheckOutDate,
        PromoCode: x.PromoCode,
        SpecialRequest: x.SpecialRequest,
        DiscountAmount: x.OriDiscountAmount || 0,
        RegistrationStatus: !IsPostPaid
          ? BookingStatus.Pending
          : BookingStatus.Booked,
        RoomRate: x.RatePackages?.Price,
        GuestID: JSON.parse(localStorage.getItem('GuestLoginInfo')).ID,
        contactInput: x.Guests?.map(el => ({
          // ID: el.ID,
          GuestID: el.GuestID,
          MobileNo: el.MobileNo,
          FullName: el.FullName,
          Email: el.Email,
          Nationality: Nationality[el.Nationality],
          AccountID: localState?.HotelSelected?.AccountID,
        })),
      })),
    },
    onCompleted: data => {
      const dataInfo = JSON.parse(data.OnlineBookingInsert)
      if (dataInfo?.ReferenceNo && IsPostPaid === true) { 
      // if(IsPostPaid === true){
      //   console.log(IsPostPaid,'IsPostPaid')
        dispatch({
          type: 'CloseDialog',
          payload: {},
        })
        RootDispatch({
          type: 'OnSnackBar',
          payload: {
            ...RootState.OnSnackBar,
            Open: true,
            Message: 'Booked Successfully',
            onClick: () =>
              RootDispatch({
                type: 'CloseSnackBar',
                payload: {},
              }),
          },
        })
        setTimeout(() => {
          RootDispatch({
            type: 'CloseSnackBar',
            payload: {},
          })
          history.push(BOOKING_PATH.BOOKING_LIST)
        }, 2000)
      } else {
        dispatch({
          type: 'CloseDialog',
          payload: {},
        })
        localStorage.setItem('FDReceiptNo', data.OnlineBookingInsert)
        OnlinePayment({
          variables: {
            redirectUrl: `${
              window.location.hostname === 'localhost' ? 
            'http://localhost:3333' :
           'https://'+window.location.hostname}/booking/redirect/:status`,
            PayAmount:
              process.env.REACT_APP_API_URL === 'prod-build' ||
              process.env.REACT_APP_API_URL === 'uat-build'
                ? Number(TotalAmount)
                : 1,
            Detail: `${localState?.HotelSelected?.HotelName} Booking`,
            Title: `${localState?.HotelSelected?.HotelName} Booking Payment`,
            HotelID: localState?.HotelSelected?.ID,
            ReceiptNo: data.OnlineBookingInsert || null,
            // AdvancePaymentID: dataInfo.AdvancePaymentID
          },
        }).then(i => {
          if (i.data.OnlinePayment.code === 'SUCCESS') {
            localStorage.setItem(
              'CheckOutID',
              i.data.OnlinePayment.item.checkoutId
            )
            localStorage.setItem('HotelID', localState?.HotelSelected?.ID)
            dispatch({
              type: 'CloseDialog',
              payload: {},
            })
            RootDispatch({
              type: 'OnSnackBar',
              payload: {
                ...RootState.OnSnackBar,
                Open: true,
                Message: 'Redirecting to payment gateway...',
                onClick: () =>
                  RootDispatch({
                    type: 'CloseSnackBar',
                    payload: {},
                  }),
              },
            })
            window.location.assign(i.data.OnlinePayment.item.url)
          } else {
            i.data.OnlinePayment.error.code !== ''
              ? RootDispatch({
                  type: 'OnSnackBar',
                  payload: {
                    ...RootState.OnSnackBar,
                    Open: true,
                    Message: i.data.OnlinePayment.error.message,
                  },
                })
              : RootDispatch({
                  type: 'OnSnackBar',
                  payload: {
                    ...RootState.OnSnackBar,
                    Open: true,
                    Message: 'Insert Fail.',
                  },
                })
            dispatch({
              type: 'CloseDialog',
              payload: {},
            })
          }
        })
      }
      // else {
      //   RootDispatch({
      //     type: 'OnSnackBar',
      //     payload: {
      //       ...RootState.OnSnackBar,
      //       Open: true,
      //       Message: 'Book Fail',
      //       onClick: () =>
      //         RootDispatch({
      //           type: 'CloseSnackBar',
      //           payload: {},
      //         }),
      //     },
      //   })
      // }
    },
    onError: error => {
      // eslint-disable-next-line array-callback-return
      error.graphQLErrors?.map(({ message }) => {
        RootDispatch({
          type: 'OnSnackBar',
          payload: {
            ...RootState.OnSnackBar,
            Open: true,
            Message: message,
            onClick: () =>
              RootDispatch({
                type: 'CloseSnackBar',
                payload: {},
              }),
          },
        })
      })
    },
  })
  const [OnlinePayment, { loading }] = useOnlinePaymentMutation({})

  const handleAddGuestDialog = (event, i: number) => {
    event.stopPropagation()

    dispatch({
      type: 'AnchorEl',
      payload: event.currentTarget,
    })
    dispatch({
      type: 'IndexSelected',
      payload: i,
    })
  }
  const handleUpdateGuestDialog = (i: number, i2: number, guest: any) => {
    dispatch({
      type: 'IndexSelected',
      payload: i,
    })
    dispatch({
      type: 'GuestSelected',
      payload: i2,
    })
    dispatch({
      type: 'GuestInfo',
      payload: guest,
    })
    dispatch({
      type: 'UpdateDialog',
      payload: {},
    })
  }
  const handleDeleteGuest = (i: number, i2: number) => {
    let results = state.RoomTypeSelected?.map((el, index) =>
      index === i
        ? {
            ...el,
            Guests: el.Guests.filter((_, index2) => index2 !== i2),
          }
        : el
    )
    dispatch({
      type: 'RoomTypeSelected',
      payload: results,
    })
  }
  const RoomTypeGroupBy = _.groupBy(
    state.RoomTypeSelected,
    x => x.RatePackages?.PackageName,
    
  )
  const groupedData = state?.RoomTypeSelected?.reduce((groups, item) => {
    const key = `${item.Code}_${item.RatePackages?.PackageName
    }`;
  
    if (!groups[key]) {
      groups[key] = [];
    }
  
    groups[key].push(item);
    return groups;
  }, {});
  
  const result = Object.values(groupedData);
  
  console.log(result,'');
  result.forEach((group:any, index) => {
    // Display a heading for each group
    console.log(`Group ${index + 1}:`);
  
    // Iterate through the objects in the current group and display them
    group.forEach((item, itemIndex) => {
      console.log(`Item ${itemIndex + 1}:`);
      console.log(`Code: ${item}`,item);
      console.log(`RateType: ${item.RatePackages?.PackageName}`);
      // Display other properties as needed
    });
  });
  console.log(result,'groupedData')
  const RoomTypeGroupByList = array => {
    let keys = Object.keys(array)
    let values = Object.values(array)
    let map = new Map()
    for (var i = 0; i < keys.length; i++) {
      map.set(keys[i], values[i])
    }
    return [...map]?.map(([PackageName, Data]) => ({
      PackageName,
      Data,
    }))
  }
  console.log(RoomTypeGroupByList(RoomTypeGroupBy), 'RoomTypeGroupBy')
  const AddPopOver = (
    <Popover
      className="filter-popover"
      id={ID}
      open={Open}
      anchorEl={state.AnchorEl}
      onClose={() => {
        dispatch({ type: 'AnchorEl', payload: null })
        dispatch({ type: 'IndexSelected', payload: null })
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      PaperProps={{
        style: {
          padding: '12px',
        },
      }}
    >
      <div
        className="xsTitle pointer"
        onClick={() => {
          dispatch({ type: 'AddDialog', payload: {} })
          dispatch({ type: 'AnchorEl', payload: null })
        }}
      >
        {' '}
        Add Guest{' '}
      </div>
    </Popover>
  )

  const tncDialog = (
    <>
      <CommonDialog
       open={state?.TnCDialog}
       onClose={() => {
        dispatch({
          type: 'TnCDialog',
          payload: false,
        })
       }}
       sections={{
        content: (
          <>
            <div className="m-b-8 title color-blue-2">
              TERMS AND CONDITIONS
            </div>
            <div
              style={{ fontSize: 14, padding: 3 }}
              dangerouslySetInnerHTML={{
                __html: localState?.HotelSelected?.OnlineTnC,
              }}
            />
          </>
        ),
        footer: {
          children: (
            <Button
                variant="contained"
                color="primary"
                size="small"
                className="action-btn"
                onClick={() =>
                  dispatch({
                    type: 'TnCDialog',
                    payload: false,
                  })
                }
              >
                Close
            </Button>
          )
        }
       }}
      />
    </>
  )
  return (
    <>
      {bookLoading && <Spinner />}
      <ContentWrapper footer>
        <StyledCardContent>
          <Grid
            container
            justify="flex-start"
            className="m-b-16"
            style={{ textAlign: 'center' }}
          >
            <Grid item xs={4}>
              <div className="desc color-text-2 m-b-4">Check In</div>
              <div className="title ">
                {format(new Date(localState.CheckInDate), 'dd.MM.yyyy')}
              </div>
            </Grid>
            <Grid item xs={4} className="auto-margin">
              <div className="auto-margin stay-duration">
                <img
                  src={DurationIcon}
                  alt="duration"
                  width={15}
                  height={15}
                  className="m-b-4"
                />
              </div>
              <div className="desc color-text m-b-2">{NoOfDays} night(s)</div>
            </Grid>
            <Grid item xs={4}>
              <div className="desc color-text-2 m-b-4">Check Out</div>
              <div className="title ">
                {format(new Date(localState.CheckOutDate), 'dd.MM.yyyy')}
              </div>
            </Grid>
          </Grid>
          {state.RoomTypeSelected?.length === 0 && (
            <div
              className=" xsTitle m-t-10 m-b-10"
              style={{ textAlign: 'center' }}
            >
              No Record Found.
            </div>
          )}
          {  result?.map((group:any, i) => {

  

            return (
              <Grid
                key={i}
                container
                justify="flex-start"
                className=" m-b-12 room-type-section"
              >
                <Grid item xs={3}>
                  <Avatar
                    src={
                      (group[0]?.RoomTypeGallery &&
                        group[0]?.RoomTypeGallery[0].Gallery?.ImageURL) ||
                      DefaultImg
                    }
                    alt={group[0]?.Code}
                    variant="rounded"
                  />
                </Grid>
                <Grid item xs={9}>
                  <div className="flex-width m-b-4">
                    <span className="fs-14 fw-semibold flex-space color-text-1">
                      {group[0]?.Description}{' '}
                      {`x ${group[0]?.RatePackages?.TotalNumOfRoom}`}
                    </span>
                    <span className="fs-14 fw-semibold color-text-1 icon-text ">
                      <MonetizationOnIcon />{' '}
                      {amtStr(
                        group[0]?.RatePackages?.Price *
                          group[0]?.RatePackages?.TotalNumOfRoom *
                          NoOfDays
                      )}
                    </span>
                  </div>
                  <div className="flex-width ">
                    <span className="desc color-text-2">
                      {group[0]?.RatePackages?.PackageName}{' '}
                    </span>
                  </div>
                  <div className="special-request-section">
                    <TextareaAutosize
                      aria-label="Special Request"
                      placeholder="Special Request"
                      value={group[0]?.SpecialRequest}
                      onChange={e => {
                        const newArr: any = state.RoomTypeSelected
                        let selected = newArr[i]
                        selected.SpecialRequest = e.target.value
                        dispatch({
                          type: 'RoomTypeSelected',
                          payload: newArr,
                        })
                      }}
                    />
                    {/* <TextField
                    variant="outlined"
                    fullWidth
                    placeholder="Special Request here..."
                    value={el.SpecialRequest}
                    // InputProps={{
                    //   endAdornment: (
                    //     <InputAdornment position="end">
                    //       <AccountCircle />
                    //     </InputAdornment>
                    //   ),
                    // }}
                    onChange={e => {
                      const newArr: any = state.RoomTypeSelected
                      let selected = newArr[i]
                      selected.SpecialRequest = e.target.value
                      dispatch({
                        type: 'RoomTypeSelected',
                        payload: newArr,
                      })
                    }}
                  /> */}
                    <IconButton
                      size="small"
                      onClick={e => {
                        dispatch({
                          type: 'RoomTypeSelected',
                          payload: state.RoomTypeSelected.filter(
                            (_, deleteIndex) => deleteIndex !== i
                          ),
                        })
                      }}
                    >
                      <img src={RemoveIcon} alt="Delete" />
                    </IconButton>
                  </div>
                </Grid>
              </Grid>
            )
                   
                
                   
                  })}
          <div className="b-b m-b-12" />
          <Grid
            container
            justify="flex-start"
            className="promo-section m-b-12 "
          >
            <Grid item xs={8}>
              <div className="xsTitle color-text-1 m-b-4">Promo code</div>
              <TextField
                variant="outlined"
                fullWidth
                placeholder="Enter Promo here..."
                error={state.PromoCode.Error}
                helperText={state.PromoCode.HelperText}
                value={state.PromoCode.Value}
                onChange={e => {
                  dispatch({
                    type: 'PromoCode',
                    payload: {
                      ...state.PromoCode,
                      Value: e.target.value,
                      Error: false,
                      HelperText: '',
                    },
                  })
                  if (DiscountAmount > 0) {
                    dispatch({
                      type: 'RoomTypeSelected',
                      payload: state.RoomTypeSelected?.map(x => ({
                        ...x,
                        DiscountAmount: 0,
                      })),
                    })
                  }
                }}
              />
            </Grid>
            <Grid item xs={4} className="apply-btn-section">
              <Button
                variant="contained"
                size="small"
                color="primary"
                className="apply-btn"
                onClick={() =>
                  DisCountByPromoCodeQuery({
                    variables: {
                      HotelID: localState.HotelSelected?.ID,
                      PromoCode: state.PromoCode.Value,
                      PromoCodeInput: state.RoomTypeSelected?.map(x => ({
                        RoomTypeID: x.ID,
                        RatePolicyID: x.RatePackages?.RatePolicyID,
                        BaseAmt: x.RatePackages?.Price,
                      })),
                    },
                  })
                }
              >
                Apply
              </Button>
            </Grid>
          </Grid>
          <div className="b-b m-b-14" />
          {/* <div className="flex-width color-text-1 m-b-8 ">
            <span className="xsTitle flex-space">
              Service Charge ({ServiceCharge}%)
            </span>
            <span className="xsTitle icon-text">
              {' '}
              <MonetizationOnIcon />
              {amtStr(ServiceChargeAmount)}
            </span>
          </div>
          <div className="flex-width color-text-1 m-b-8 ">
            <span className="xsTitle flex-space">Tax ({Tax}%)</span>
            <span className="xsTitle icon-text">
              {' '}
              <MonetizationOnIcon />
              {amtStr(TaxAmount)}
            </span>
          </div> */}
          <div className="flex-width color-text-1 m-b-8 ">
            <span className="xsTitle flex-space">Discount</span>
            <span className="xsTitle icon-text">
              {' '}
              <MonetizationOnIcon />
              {amtStr(DiscountAmount)}
            </span>
          </div>
          <div className="b-b m-b-10" />
          <div className="flex-width color-text-1 m-b-8 ">
            <span className="xsTitle flex-space icon-text">
              Taxes & Fees{' '}
              <InfoOutlinedIcon
                style={{ marginLeft: '4px' }}
                onClick={() =>
                  dispatch({
                    type: 'TaxesDialog',
                    payload: true,
                  })
                }
              />
            </span>
            <span className="xsTitle icon-text">
              {' '}
              <MonetizationOnIcon />
              {amtStr(TotalTaxesFeeAmount)}
            </span>
          </div>
          <div className="b-b m-b-10" />

          <div className="flex-width  ">
            <span className="fs-14 fw-bold flex-space">Total</span>
            <span className="fs-14 fw-bold title icon-text">
              <MonetizationOnIcon /> {amtStr(TotalAmount)}
            </span>
          </div>
        </StyledCardContent>
        <>
          <div className="flex-width m-b-15 m-t-15">
            <span className="smTitle">Contact Details</span>
          </div>
          <StyledCardContent>
            <TextField
              label="Name"
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              required
              value={state.Contact?.FullName}
              onChange={e =>
                dispatch({
                  type: 'Contact',
                  payload: {
                    ...state.Contact,
                    FullName: e.target.value,
                  },
                })
              }
              disabled={loggedInGuestProfile?.Contact?.FullName ? true : false}
            />
            <Grid container justify="flex-start">
              <Grid item xs={6} style={{ paddingRight: '6px' }}>
                <TextField
                  label="Contact No."
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  required
                  value={state?.Contact?.MobileNo}
                  onChange={e => {
                    dispatch({
                      type: 'Contact',
                      payload: {
                        ...state.Contact,
                        MobileNo: e.target.value,
                      },
                    })
                  }}
                  disabled={
                    loggedInGuestProfile?.Contact?.MobileNo ? true : false
                  }
                />
              </Grid>
              <Grid item xs={6} style={{ paddingRight: '6px' }}>
                <Autocomplete
                  fullWidth
                  options={NationalityEnum}
                  getOptionLabel={option => option || ''}
                  disabled={
                    loggedInGuestProfile?.Contact?.Nationality ? true : false
                  }
                  value={state.Contact.Nationality || ''}
                  onChange={(_, value) => {
                    dispatch({
                      type: 'Contact',
                      payload: {
                        ...state.Contact,
                        Nationality: value,
                      },
                    })
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      required
                      label="Nationality"
                      margin="normal"
                      style={{ width: '100%' }}
                    />
                  )}
                />
              </Grid>
            </Grid>

            <TextField
              label="Email Address"
              margin="normal"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              required
              value={  state?.Contact?.Email || loggedInGuestProfile?.Contact?.Email }
              disabled={
                loggedInGuestProfile?.Contact?.Email ? true : false
              }
              onChange={e =>
                dispatch({
                  type: 'Contact',
                  payload: {
                    ...state.Contact,
                    Email: e.target.value,
                  },
                })
              }
            />
          </StyledCardContent>
          <StyledCardContent>
          <Grid item xs={8}>
            <div className=''>
              <span className="xsTitle flex-width">Terms and conditions</span>
            </div>
          </Grid>
          <div className=''>
            <FormGroup >
              <FormControlLabel
                control={
                  <Checkbox
                    size='small'
                    color='primary'
                    defaultChecked
                    checked={check}
                    onChange={() => {
                      check === true
                        ? setCheck(false)
                        : setCheck(true)
                    }}
                  />
                }
                label={
                  <span className="mdDesc">
                    I have read and agree to the {''}
                    <span 
                      className='mdDesc color-blue-2' 
                      style= {{ textDecoration: "underline"}}
                      onClick={() => {
                        console.log('clicked')
                        dispatch({
                          type: 'TnCDialog',
                          payload: true,
                        })
                      }}
                    >
                      terms and conditions.
                    </span>
                  </span>
                }
              ></FormControlLabel>
            </FormGroup>
          </div>
        </StyledCardContent>
        </>
        {/* <div className="">
          {state.RoomTypeSelected?.map((x, i) => (
            <Accordion
              className="accordion-wrapper"
              expanded={state.Expanded === i}
              onChange={handleExpand(i)}

              //  expanded={expanded === 'panel1'} onChange={handleChange('panel1')}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <div className="xsTitle accord-title ">
                  <span>
                    {' '}
                    Room {i + 1} {x.Description}{' '}
                  </span>
                  <div
                    className="more-icon"
                    onClick={e => handleAddGuestDialog(e, i)}
                  >
                    <img src={MoreIcon} alt="" />
                  </div>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                {x.Guests?.map((el, i2) => (
                  <div>
                    <Grid
                      justify="flex-start"
                      container
                      className="accord-content m-b-15"
                    >
                      <Grid item xs={9}>
                        <div className="mdDesc color-text-2 icon-text m-b-4">
                          <img src={PersonIcon} alt="person" />
                          {el.FullName}
                        </div>
                        <div className="desc color-text-2 text-overflow">
                          <a href={`tel:${el?.MobileNo}`}> {el.MobileNo}</a>
                          <span className="text-separator">|</span>{' '}
                          <a href={`mailto:${el?.Email}`}> {el.Email}</a>
                        </div>
                      </Grid>
                      <Grid item xs={3} className="guest-details">
                        <IconButton
                          size="small"
                          className="edit-icon"
                          onClick={() => handleUpdateGuestDialog(i, i2, el)}
                        >
                          <img src={EditIcon} alt="Delete" />
                        </IconButton>

                        <IconButton
                          size="small"
                          disabled={loggedInGuestProfile?.ID === el.GuestID}
                          onClick={() => handleDeleteGuest(i, i2)}
                        >
                          <img
                            src={RemoveIcon}
                            alt="Delete"
                            style={{
                              marginRight: 0,
                            }}
                          />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </div>
                ))}
              </AccordionDetails>
            </Accordion>
          ))}
        </div> */}
      </ContentWrapper>
      <TaxesFeeDialog
        state={state}
        dispatch={dispatch}
        ServiceCharge={ServiceCharge}
        ServiceChargeAmount={ServiceChargeAmount}
        Tax={Tax}
        TaxAmount={TaxAmount}
        TotalTaxesFeeAmount={TotalTaxesFeeAmount}
      />
      <StyledFooter
        sections={{
          isSingle: true,
          option: [
            {
              props: {
                onClick: () =>
                  !IsPostPaid
                    ? dispatch({
                        type: 'PaymentDialog',
                        payload: {},
                      })
                    : OnlineBookingInsert(),
                disabled:
                  DisabledFooterBtn ||
                  CheckRoomListWithoutGuest ||
                  // CheckRoomListWithoutGuestInfo||
                  !state?.Contact?.FullName || 
                  !state?.Contact?.MobileNo ||
                  !state?.Contact?.Email ||
                  !state?.Contact?.Nationality ||
                  check === false,
              },
              name: FooterLabel,
            },
          ],
        }}
      />
      {AddPopOver}
    {tncDialog}
      <BookingSummaryDialog
        Mode={'Summary'}
        state={state}
        dispatch={dispatch}
        LoggedGuest={loggedInGuestProfile}
        TotalDiscountAmount={TotalAmount}
        OnlineBookingInsert={OnlineBookingInsert}
      />
    </>
  )
}
