import {
  ContentWrapper,
  StyledCardContent,
  StyledFooter,
} from '@ifca-ui/neumorphism'
import {
  Checkbox,
  ListItem,
  ListItemIcon,
  ListItemText,
  SvgIcon,
} from '@material-ui/core'
import { ReactComponent as CheckBoxIcon } from 'assets/svg/CheckBoxIcon.svg'
import InvoiceIcon from 'assets/svg/invoice.svg'
import BillsIcon from 'assets/svg/service/bills.svg'
import { RedirectUrl } from 'containers/App/Client'
import AppContext, { AppContextProps } from 'containers/Context/Context'
import { IAction } from 'containers/Context/Types'
import { amtStr } from 'containers/Utils/numFormatter'
import dateFormat from 'dateformat'
import {
  PaymentClass,
  PaymentStatus,
  PaymentType,
  useBillingByRegistrationQuery,
  useFolioListingByRegistrationQuery,
  useFrontDeskPaymentInsertMutation,
  useIsPaymentGateWayHotelQuery,
  useOnlinePaymentMutation,
  usePaymentUpdateMutation,
} from 'generated/graphql'
import React, { FC, Reducer, useContext, useEffect, useReducer } from 'react'
import { useHistory, useLocation } from 'react-router'
import { SERVICE_PATH } from './Routes'
import queryString from 'query-string'
import { CheckOutDialog } from './CheckOutDialog'
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn'

interface Props {
  AdvancePaymentTotal: any
  PaymentSelected: any
  CheckOutDialog?: boolean
}
export const Bills = ({ Mode }) => {
  const { RootState, RootDispatch } = useContext<AppContextProps>(AppContext)
  const history = useHistory()
  const location = useLocation()
  const PaymentInfo = queryString.parse(location.search)

  const localState = location.state as any
  const {
    data: IsPaymentGateWayHotel = { IsPaymentGateWayHotel: null },
  } = useIsPaymentGateWayHotelQuery({
    fetchPolicy: 'no-cache',
    variables: {
      HotelID: localState?.Hotel?.ID || JSON.parse(localStorage.getItem('LocalState'))?.Hotel?.ID,
    },
  })
  console.log(IsPaymentGateWayHotel?.IsPaymentGateWayHotel)
  const {
    // refetch,
    // loading,
    data: { BillingByRegistration } = { BillingByRegistration: [] },
  } = useBillingByRegistrationQuery({
    variables: {
      RegistrationID: [
        localState?.Registration?.ID ||
          JSON.parse(localStorage.getItem('LocalState'))?.Registration?.ID,
      ],
      BookingID:
        localState?.Registration?.Booking?.ID ||
        JSON.parse(localStorage.getItem('LocalState'))?.Registration?.Booking
          ?.ID,
      IsHMS: false,
    },
    fetchPolicy: 'no-cache',
  })
  const {
    // refetch,
    // loading,
    data: { FolioListingByRegistration } = { FolioListingByRegistration: [] },
  } = useFolioListingByRegistrationQuery({
    variables: {
      HotelID:
        localState?.Hotel?.ID ||
        JSON.parse(localStorage.getItem('LocalState'))?.Hotel?.ID,
      BookingID:
        localState?.Registration?.Booking?.ID ||
        JSON.parse(localStorage.getItem('LocalState'))?.Registration?.Booking
          ?.ID,
      RegistrationID:
        localState?.Registration?.ID ||
        JSON.parse(localStorage.getItem('LocalState'))?.Registration?.ID,
      AccountID:
        localState?.Hotel?.AccountID ||
        JSON.parse(localStorage.getItem('LocalState'))?.Hotel?.AccountID,
    },
  })
  const initialState: Props = {
    PaymentSelected: [],
    AdvancePaymentTotal: 0,
    CheckOutDialog: false,
  }
  const reducer: Reducer<Props, IAction> = (state, action) => {
    switch (action.type) {
      case 'reset':
        return initialState
      default:
        return { ...state, [action.type]: action.payload }
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState)
  // Payment update (online )
  const [PaymentUpdate] = usePaymentUpdateMutation({
    variables: {
      CheckOutID: localStorage.getItem('CheckOutID'),
      IsGuestApp: true,
      OrderID: PaymentInfo.orderId as string,
      ReceiptNo: localStorage.getItem('FDReceiptNo'),
      HotelID: localStorage.getItem('HotelID'),
      AppName:'loyalty-app',
      Status:
        (PaymentInfo.status as string)?.charAt(0).toUpperCase() +
        (PaymentInfo.status as string)?.slice(1).toLowerCase(),
    },
    onCompleted: data => {
      if (data.PaymentUpdate) {
        dispatch({ type: 'CheckOutDialog', payload: true })
        RootDispatch({
          type: 'OnSnackBar',
          payload: {
            ...RootState.OnSnackBar,
            Open: true,
            Message: 'Payment Succesfully',
            onClick: () =>
              RootDispatch({
                type: 'CloseSnackBar',
                payload: {},
              }),
          },
        })
        localStorage.removeItem('FDReceiptNo')
        localStorage.removeItem('LocalState')
        localStorage.removeItem('isRoomService')
        localStorage.removeItem('HotelID')
        localStorage.removeItem('CheckOutID')
        localStorage.removeItem('frontdeskpayment')
        // history.replace('/services/checkout-bills')
      } else {
        RootDispatch({
          type: 'OnSnackBar',
          payload: {
            ...RootState.OnSnackBar,
            Open: true,
            Message: 'Payment Fail',
            onClick: () =>
              RootDispatch({
                type: 'CloseSnackBar',
                payload: {},
              }),
          },
        })
        history.replace('/services/checkout-bills')
      }
    },
    onError: error => {
      error.graphQLErrors?.map(({ message }) => {
        RootDispatch({
          type: 'OnSnackBar',
          payload: {
            ...RootState.OnSnackBar,
            Open: true,
            Message: message,
            onClick: () =>
              RootDispatch({
                type: 'CloseSnackBar',
                payload: {},
              }),
          },
        })
      })
      localStorage.removeItem('FDReceiptNo')
      localStorage.removeItem('CheckOutID')
      localStorage.removeItem('HotelID')
    },
  })
  useEffect(() => {
    if (PaymentInfo?.orderId) {

      if(localStorage.getItem('frontdeskpayment') && PaymentInfo.status as string === 'SUCCESS'){
        FrontDeskPaymentInsert({
          variables: {
            TotalPaymentAmount: JSON.parse(localStorage.getItem('frontdeskpayment'))?.TotalPaymentAmount,
            input: JSON.parse(localStorage.getItem('frontdeskpayment'))?.input,
            AppName:'loyalty-app',
          },
        }).then(i => {
          if (i.data.FrontDeskPaymentInsert) {
            PaymentUpdate()
          } else {
            RootDispatch({
              type: 'OnSnackBar',
              payload: {
                ...RootState.OnSnackBar,
                Open: true,
                Message: 'Payment Fail',
              },
            })
          }
        })
      }
      else{
        RootDispatch({
          type: 'OnSnackBar',
          payload: {
            ...RootState.OnSnackBar,
            Open: true,
            Message: 'Payment has been cancelled',
            onClick: () =>
              RootDispatch({
                type: 'CloseSnackBar',
                payload: {},
              }),
          },
        })
        localStorage.removeItem('FDReceiptNo')

        localStorage.removeItem('isRoomService')

        localStorage.removeItem('CheckOutID')
        localStorage.removeItem('frontdeskpayment')
        history.push('/services/checkout-bills/')
      }
      
    }
  }, [])
  useEffect(() => {
    RootDispatch({
      type: 'HeaderSection',
      payload: {
        left: {
          icon: 'leftArrow',
          title:
            Mode === 'Bill' || localState?.Mode === 'Bill'
              ? 'Bills'
              : 'Check Out',
          props: {
            onClick: () => history.push(SERVICE_PATH.LIST),
          },
        },
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    if (BillingByRegistration?.length > 0) {
      dispatch({
        type: 'PaymentSelected',
        payload: BillingByRegistration?.filter(
          x => x.Type !== 'Payment' && x.Type !== 'AdvancePayment'
        ),
      })
    }
  }, [BillingByRegistration?.length > 0])
  const OutstandingPayment =
    BillingByRegistration?.filter(
      x => x.Type !== 'Payment' && x.Type !== 'AdvancePayment'
    ).reduce((acc, curr) => {
      return acc + curr.TrxAmount
    }, 0) || 0
    const AdvancedPayment =
  BillingByRegistration?.filter(
  x => x.ChargeType === 'Advance' || x.Type === 'AdvancePayment').reduce((acc, curr) => {
    return acc + curr.TrxAmount
  }, 0) || 0
  const Tax =
  BillingByRegistration?.filter(
  x => x.Type === 'Tax').reduce((acc, curr) => {
    return acc + curr.TrxAmount
  }, 0) || 0
  console.log(AdvancedPayment, "AdvancedPayment")
  console.log(Tax, "Tax")
  const FinalAmount = OutstandingPayment - AdvancedPayment
  function not(a: number[], b: number[]) {
    return a?.filter(value => b.indexOf(value) === -1)
  }

  function intersection(a: number[], b: number[]) {
    return a?.filter(value => b.indexOf(value) !== -1)
  }

  function union(a: number[], b: number[]) {
    return [...a, ...not(b, a)]
  }

  const handleToggle = (value: number) => () => {
    const currentIndex = state.PaymentSelected?.indexOf(value)
    const newChecked = [...state.PaymentSelected]

    if (currentIndex === -1) {
      newChecked?.push(value)
    } else {
      newChecked?.splice(currentIndex, 1)
    }
    dispatch({
      type: 'PaymentSelected',
      payload: newChecked,
    })
  }
  const numberOfChecked = (items: Array<any>) =>
    intersection(state.PaymentSelected, items).length

  //! Handle checkbox select all

  const handleToggleAll = (items: Array<any>) => () => {
    if (numberOfChecked(items) === items.length) {
      dispatch({
        type: 'PaymentSelected',
        payload: not(state.PaymentSelected, items),
      })
    } else {
      dispatch({
        type: 'PaymentSelected',
        payload: union(state.PaymentSelected, items),
      })
    }
  }

  useEffect(() => {
    dispatch({
      type: 'AdvancePaymentTotal',
      payload: BillingByRegistration?.filter(x => x.Type === 'AdvancePayment' || x.ChargeType === 'Advance').reduce?.((acc, curr) => {
        return acc + curr.TrxAmount
      }, 0),
    })
  }, [BillingByRegistration?.length > 0])
  const TotalAmount =
  state.PaymentSelected?.reduce?.((acc, curr) => {
    return curr.ChargeType === 'AdvancePayment' ? acc - curr.TrxAmount : acc + curr.TrxAmount
  }, 0) || 0
  const Input: any[] = state.PaymentSelected.map(el => {
    return {
      BookingID: localState?.Registration?.Booking?.ID,
      HotelID: localState?.Hotel?.ID,
      PaymentType: PaymentType.Online,
      Amount: el?.TrxAmount,
      BaseAmount: el?.BaseAmount,
      ServiceCharge: el?.ServiceCharge,
      TaxAmount: el?.TaxAmount,
      RegistrationID: el.RegistrationID,
      ChargeType:
      (el.ChargeType === 'Booking' || el.ChargeType === 'ExtendStay')
          ? el.ChargeType
          : 'LateCheckOut',
      PaymentClass: PaymentClass['Room'],
      TransactionType: el.TransactionType,
      ModuleName: 'InHouse',
      PaymentStatus: PaymentStatus['Pending'],
      TrxDate: el.TrxDate,
      LedgerType: el.LedgerType,
      LedgerTypeID: el.ID,
      AccountID: localState?.Hotel?.AccountID,
      SplitGroup: el?.SplitGroup,
        }
  })
  const [OnlinePayment] = useOnlinePaymentMutation()
  const [
    FrontDeskPaymentInsert,
    { loading: InsertLoading },
  ] = useFrontDeskPaymentInsertMutation({})
  const handleOlinePayment = () => {

    localStorage.setItem('frontdeskpayment',JSON.stringify(
      {TotalPaymentAmount: TotalAmount,
      input: Input}))

    localStorage.setItem(
      'FDReceiptNo',
      "0"
    )
    localStorage.setItem('LocalState', JSON.stringify(localState))
    OnlinePayment({
      variables: {
        redirectUrl:
        `${
          window.location.hostname === 'localhost' ? 
        'http://localhost:3333' :
       'https://'+window.location.hostname}/services/redirect/:status`,
        PayAmount:
          process.env.REACT_APP_API_URL === 'prod-build' ||
          process.env.REACT_APP_API_URL === 'uat-build'
            ? Number(TotalAmount)
            : 1,
        Detail: `Bills Payment`,
        Title: ` Bills Payment`,
        HotelID: localState?.Hotel?.ID,

        ReceiptNo: "FrontdeskPayment",
      },
    }).then(i => {
      if (i.data.OnlinePayment?.code === 'SUCCESS') {
        localStorage.setItem(
          'CheckOutID',
          i.data.OnlinePayment.item.checkoutId
        )
        localStorage.setItem('HotelID', localState?.Hotel?.ID)
        RootDispatch({
          type: 'OnSnackBar',
          payload: {
            ...RootState.OnSnackBar,
            Open: true,
            Message: 'Redirecting to payment gateway...',
          },
        })
        window.location.assign(i.data.OnlinePayment.item.url)
      } else {
        i.data.OnlinePayment.error.code !== ''
          ? RootDispatch({
              type: 'OnSnackBar',
              payload: {
                ...RootState.OnSnackBar,
                Open: true,
                Message: i.data.OnlinePayment.error.message,
              },
            })
          : RootDispatch({
              type: 'OnSnackBar',
              payload: {
                ...RootState.OnSnackBar,
                Open: true,
                Message: 'Insert Fail.',
              },
            })
      }
    })

  }
  const Customlist = (items: Array<any>, type: string) => (
    <div className="flex-width bills-wrapper m-b-15">
      <ListItem disableGutters>
      {Mode === 'Bill' && type !== 'AdvancePayment' ? (
          <ListItemIcon>
            <Checkbox
              edge="start"
              onClick={handleToggleAll(items)}
              checked={
                numberOfChecked(items) === items.length && items.length !== 0
              }
              indeterminate={
                numberOfChecked(items) !== items.length &&
                numberOfChecked(items) !== 0
              }
              disabled={items.length === 0}
              tabIndex={-1}
              color="primary"
              checkedIcon={
                <SvgIcon component={CheckBoxIcon} viewBox="0 0 30 30" />
              }
            />
          </ListItemIcon>
        ) : null}
        <ListItemText
          primary={
            <div className="flex-width">
              <span className="flex-space color-text smTitle">
              {type === 'RoomCharge'
                  ? 'Room Charges'
                  : type === 'Tax'
                    ? 'Tax'
                    : type === 'Payment' || type === 'AdvancePayment'
                      ? 'Advance Payment'
                      : 'Room Service'}
              </span>
            </div>
          }
        />
      </ListItem>
      <div className="b-b  m-b-6" />
      {items?.sort((x, y) =>
  x.TrxDate > y.TrxDate ? 1 : -1
 )?.map((x, i) => (
        <ListItem disableGutters key={i} onClick={type === 'AdvancePayment' ? 0 as any : handleToggle(x)}>
          {Mode === 'Bill' && type !== 'AdvancePayment' ? (
            <ListItemIcon>
              <Checkbox
                edge="start"
                checked={state.PaymentSelected?.indexOf(x) !== -1}
                tabIndex={-1}
                color="primary"
                checkedIcon={
                  <SvgIcon component={CheckBoxIcon} viewBox="0 0 30 30" />
                }
              />
            </ListItemIcon>
          ) : null}
          <ListItemText
            primary={
              <div className="flex-width">
                <span className="flex-space color-text-1 xsTitle">
                  {x.Description}
                </span>
                <span className=" color-text-1 xsTitle icon-text">
                <MonetizationOnIcon /> {
                    type === 'Payment' || type === 'AdvancePayment'
                      ? ('(') + amtStr(  x.TrxAmount  ) + (')')
                      : amtStr(x.TrxAmount)
                  }
                </span>
              </div>
            }
            secondary={
              <span className="desc color-text-2">
                {dateFormat(x.TrxDate, 'dd mmm yyyy')}
              </span>
            }
          />
        </ListItem>
      ))}
    </div>
  )
  return (
    <>
      <ContentWrapper footer>
        <StyledCardContent>
          <div className="flex-width">
            <div className="flex-space auto-margin">
              <div
                className="mdDesc color-text-2 m-b-4"
                onClick={() =>
                  dispatch({ type: 'CheckOutDialog', payload: true })
                }
              >
                Outstanding Amount
              </div>
              <div className="fs-30 fw-bold color-red ">
              <MonetizationOnIcon /> {FinalAmount > 0 ? amtStr(FinalAmount) : 0}{' '}
              </div>
            </div>
            <div>
              <img
                src={BillsIcon}
                alt=""
                style={{
                  marginRight: '-26px',
                  marginBottom: '-20px',
                }}
              />
            </div>
          </div>
        </StyledCardContent>
        <StyledCardContent>
          { localState?.Registration?.PrintRate === true ? Customlist(
            BillingByRegistration?.filter(x => x.Type === 'Room'),
            'RoomCharge'
          ) : ''}
          {BillingByRegistration?.filter(x => x.Type === 'Incidental').length > 0 ? Customlist(
            BillingByRegistration?.filter(x => x.Type === 'Incidental'),
            'Incidental'
          ):''}
           {
           BillingByRegistration?.filter(x => x.Type === 'Tax').length > 0 ?
           Customlist(
            BillingByRegistration?.filter(x => x.Type === 'Tax'),
            'Tax'
          ):''}
          {/* {Customlist(
            BillingByRegistration?.filter(x => x.Type === 'Payment'),
            'Payment'
          )} */}
          {Customlist(
            BillingByRegistration?.filter(x => x.Type === 'AdvancePayment' || x.ChargeType === 'Advance'),
            'AdvancePayment'
          )}
          <div className="b-b m-b-6" />
          <div className="flex-width  ">
            <span className="fs-14 fw-bold flex-space">Total</span>
            <span className="fs-14 fw-bold title icon-text">
              {' '}
              <MonetizationOnIcon /> {amtStr(TotalAmount-(state.AdvancePaymentTotal||0))}
            </span>
          </div>
        </StyledCardContent>
        <StyledCardContent>
          <div className="flex-width  ">
            <span className="fs-14 fw-bold flex-space">Bill History</span>
          </div>
          <div className="b-b m-b-8 m-t-6" />
          {FolioListingByRegistration?.map((x, i) => (
            <div className="flex-width m-b-12" key={i}>
              <div className="flex-space">
                <div className=" color-text-1 xsTitle m-b-4">
                  Invoice #{x.FolioNo}
                </div>
                <div className="desc color-text-2">
                  {dateFormat(x.FolioDate, 'dd mmm yyyy')}
                </div>
              </div>
              <div
                onClick={() =>
                  history.push(SERVICE_PATH.BILLS_INVOICE, {
                    ...x,
                    localState: localState,
                    Mode: Mode,
                  })
                }
              >
                <img
                  src={InvoiceIcon}
                  alt="invoice"
                  width={24}
                  height={24}
                  className="cursor"
                />
              </div>
            </div>
          ))}
        </StyledCardContent>
      </ContentWrapper>
      <CheckOutDialog
        state={state}
        dispatch={dispatch}
        RootState={RootState}
        RootDispatch={RootDispatch}
        HotelDetails={
          localState?.Hotel ||
          JSON.parse(localStorage.getItem('LocalState'))?.Hotel
        }
        RegistrationDetails={
          localState?.Registration ||
          JSON.parse(localStorage.getItem('LocalState'))?.Registration
        }
        RoomDetails={
          localState?.Registration?.Room ||
          JSON.parse(localStorage.getItem('LocalState'))?.Registration?.Room
        }
      />
      <StyledFooter
        sections={{
          isSingle: true,
          option: [
            {
              props: {
                onClick: () =>
                  (!IsPaymentGateWayHotel?.IsPaymentGateWayHotel || localState?.Hotel?.IsPostPaid === true)
                    ? RootDispatch({
                        type: 'OnSnackBar',
                        payload: {
                          ...RootState.OnSnackBar,
                          Open: true,
                          Message:
                            'Please settle your bill at Front Desk counter.',
                        },
                      })
                    : handleOlinePayment(),
                disabled: TotalAmount === 0,
              },
              name: 'Pay Now',
            },
          ],
        }}
      />
    </>
  )
}
