import React, { FC, Reducer, useContext, useEffect, useReducer } from 'react'
import {
  CommonDialog,
  Spinner,
  ContentWrapper,
  StyledCardContent,
  StyledFooter,
} from '@ifca-ui/neumorphism'
import DoneIcon from 'assets/svg/successful.svg'
import { useHistory, useLocation } from 'react-router'
import AppContext, { AppContextProps } from 'containers/Context/Context'
import OrderReview from 'assets/svg/order-review.svg'
import { SERVICE_PATH } from '../Routes'
import dateFormat from 'dateformat'
import { amtStr } from 'containers/Utils/numFormatter'
import {
  TaxIdentifier,
  useComputeTaxArrByHotelLazyQuery,
  useGuestRoomServiceInsertMutation,
  useIsPaymentGateWayHotelQuery,
  useServicePaymentSettingQuery,
  useOnlinePaymentMutation,
} from 'generated/graphql'
import { DialogMode } from 'containers/Utils/CommonTypes'
import { IAction } from 'containers/Context/Types'
import { Avatar, Button, useMediaQuery, useTheme } from '@material-ui/core'
import { RedirectUrl } from 'containers/App/Client'
import { PaymentDialog } from '../PaymentDialog'
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn'

interface Props {
  ReqType?: string
}
interface localProps {
  Dialog?: {
    Open?: boolean
    Mode?: DialogMode
  }
  OrderMode: string
  PaymentDialog?: {
    Open?: boolean
    Mode?: DialogMode
  }
}
export const Summary: FC<Props> = ({ ReqType }) => {
  const { RootState, RootDispatch } = useContext<AppContextProps>(AppContext)
  const history = useHistory()
  const location = useLocation()
  const localState = location.state as any
  const {
    data: IsPaymentGateWayHotel = { IsPaymentGateWayHotel: null },
  } = useIsPaymentGateWayHotelQuery({
    fetchPolicy: 'no-cache',
    variables: {
      HotelID: localState?.Hotel?.ID,
    },
  })

  const [
    ComputeTaxArrByHotelQuery,
    { data: { ComputeTaxArrByHotel } = { ComputeTaxArrByHotel: [] } },
  ] = useComputeTaxArrByHotelLazyQuery({
    fetchPolicy: 'no-cache',
  })

  const {
    data: ServicePaymentSetting = { ServicePaymentSetting: null },
  } = useServicePaymentSettingQuery({
    fetchPolicy: 'no-cache',
    variables: {
      HotelID: localState?.Hotel?.ID,
    },
  })


  const initialState: localProps = {
    Dialog: {
      Open: false,
      Mode: '',
    },
    PaymentDialog: {
      Open: false,
      Mode: '',
    },
    OrderMode: 'Room',
  }

  const reducer: Reducer<localProps, IAction> = (state, action) => {
    switch (action.type) {
      case 'OpenDialog':
        return { ...state, Dialog: { Open: true, Mode: 'View' } }
      case 'PaymentDialog':
        return { ...state, PaymentDialog: { Open: true, Mode: 'Payment' } }
      case 'ClosePaymentDialog':
        return { ...state, PaymentDialog: { Open: false, Mode: '' } }
      case 'CloseDialog':
        return { ...state, Dialog: { Open: false, Mode: '' } }
      case 'Reset':
        return initialState
      default:
        return { ...state, [action.type]: action.payload }
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState)
  console.log(localState,'localState')
  useEffect(() => {
    RootDispatch({
      type: 'HeaderSection',
      payload: {
        left: {
          icon: 'leftArrow',
          title: 'Summary',
          props: {
            onClick: () =>
              history.push(
                ReqType === 'FnB'
                  ? SERVICE_PATH.REQ_ROOM_SERVICE_FORM
                  : SERVICE_PATH.REQ_ONLINE_SHOP_FORM,
                { ...localState }
              ),
          },
        },
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    ComputeTaxArrByHotelQuery({
      variables: {
        TaxInput: localState?.OrderSelected?.map(el => ({
          HotelID: localState?.Hotel?.ID,
          TaxIdentifier: TaxIdentifier['Incidental'],
          Tax: el.Tax,
          ServiceCharge: el.ServiceCharge,
          TrxAmount: el.Amount,
        })),
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localState?.OrderSelected])
  const TotalAmount = localState?.OrderSelected?.reduce((acc, curr) => {
    return acc + curr.TotalItemPrice
  }, 0)
  const [
    GuestRoomServiceInsert,
    { loading },
  ] = useGuestRoomServiceInsertMutation({})
  const Variables = mode => {
    return {
      AccountID: localState?.Hotel?.AccountID,
      BookingID: localState?.Registration?.Booking?.ID,
      PaymentMode: mode,
      BillLedgerInput: localState.OrderSelected?.map((x, i) => ({
        RegistrationID: localState?.Registration?.ID,
        HotelID: localState?.Hotel?.ID,
        IncidentalChargeID: x.ID,
        Reason: '',
        Description: x?.Description,
        TrxDate: new Date(),
        Qty: x.Quantity,
        UnitPrice: x.Amount,
        TrxAmount: ComputeTaxArrByHotel[i]?.TotalAmount,
        BaseAmount: ComputeTaxArrByHotel[i]?.BaseAmount,
        TaxAmount: ComputeTaxArrByHotel[i]?.TaxAmount,
        ServiceCharge: ComputeTaxArrByHotel[i]?.ServiceCharge,
        // DiscountAmount: 0,
        IsChargeToRoom: true,
        IsRoomService:true
      })),
    }
  }
  const handleChargeToRoom = PaymentMode => {
    dispatch({ type: 'ClosePaymentDialog', payload: {} })

    GuestRoomServiceInsert({
      variables: Variables(PaymentMode),
    }).then(i => {
      if (i.data.GuestRoomServiceInsert !== 'Fail') {
        dispatch({
          type: 'OpenDialog',
          payload: {},
        })
      } else {
        RootDispatch({
          type: 'OnSnackBar',
          payload: {
            ...RootState.OnSnackBar,
            Open: true,
            Message: 'Order Fail',
          },
        })
      }
    })
  }
  const [OnlinePayment] = useOnlinePaymentMutation()
 
  const handleOnlinePayment = PaymentMode => {
    localStorage.setItem('servicerequest',JSON.stringify(Variables(PaymentMode)))
    dispatch({ type: 'ClosePaymentDialog', payload: {} })
    localStorage.setItem('FDReceiptNo', "0")
    localStorage.setItem('isRoomService', 'true')
    OnlinePayment({
      variables: {
        redirectUrl:     `${
          window.location.hostname === 'localhost' ? 
        'http://localhost:3333' :
       'https://'+window.location.hostname}/services/redirect/:status`,
        PayAmount:
          process.env.REACT_APP_API_URL === 'prod-build' ||
          process.env.REACT_APP_API_URL === 'uat-build'
            ? Number(TotalAmount)
            : 1,
        Detail: `Order Payment`,
        Title: ` Order Payment`,
        HotelID: localState?.Hotel?.ID,
        ReceiptNo: "servicerequest",
      },
    }).then(i => {
      if (i.data.OnlinePayment?.code === 'SUCCESS') {
        localStorage.setItem(
          'CheckOutID',
          i.data.OnlinePayment.item.checkoutId
        )
        localStorage.setItem('HotelID', localState?.Hotel?.ID)
        RootDispatch({
          type: 'OnSnackBar',
          payload: {
            ...RootState.OnSnackBar,
            Open: true,
            Message: 'Redirecting to payment gateway...',
          },
        })
        window.location.assign(i.data.OnlinePayment.item.url)
      } else {
        i.data.OnlinePayment.error.code !== ''
          ? RootDispatch({
              type: 'OnSnackBar',
              payload: {
                ...RootState.OnSnackBar,
                Open: true,
                Message: i.data.OnlinePayment.error.message,
              },
            })
          : RootDispatch({
              type: 'OnSnackBar',
              payload: {
                ...RootState.OnSnackBar,
                Open: true,
                Message: 'Insert Fail.',
              },
            })
      }
    })

  
  }

  const OrderDialog = () => {
    return (
      <CommonDialog
        open={state.Dialog?.Open}
        onClose={() => {
          dispatch({
            type: 'CloseDialog',
            payload: {},
          })
        }}
        sections={{
          content: (
            <>
              <div className="hotel-logo">
                <Avatar
                  alt="companylogo"
                  src={DoneIcon}
                  variant="circle"
                  className="done-icon"
                />
              </div>
              <div className=" text-align-center fs-16 fw-medium m-b-4">
                Order accepted
              </div>
              <div className="flex-width m-b-4">
                <span className="mdDesc flex-space">Date</span>
                <span className="mdDesc ">
                  {' '}
                  {dateFormat(new Date(), 'dd mmm yyyy')}
                </span>
              </div>
              <div className="flex-width m-b-4">
                <span className="mdDesc flex-space">Room No.</span>
                <span className="mdDesc "> {localState?.Room?.RoomNo}</span>
              </div>
            </>
          ),
          footer: {
            children: (
              <div className="single-button">
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  className="action-btn"
                  onClick={() => {
                    dispatch({
                      type: 'CloseDialog',
                      payload: {},
                    })
                    history.push(
                      ReqType === 'FnB'
                        ? SERVICE_PATH.REQ_ROOM_SERVICE
                        : SERVICE_PATH.REQ_ONLINE_SHOP,
                      { ...localState, OrderSelected: null }
                    )
                  }}
                >
                  Close
                </Button>
              </div>
            ),
          },
        }}
      />
    )
  }
  const theme = useTheme()
const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
  defaultMatches: true,
})
  return (
    <>
      <ContentWrapper>
        {loading && <Spinner />}
        {/* <div style={{maxWidth:isDesktop ? '70%' : '100%',    textAlign:'center', margin:'auto'}}> */}
        <StyledCardContent>
          <div
            style={{
              marginLeft: '-14px',
              marginRight: '-14px ',
              height:'100%',
              maxHeight:'10%',
              textAlign:'center'
            }}
          >
            <img src={OrderReview} alt="payment" style={{ width: '100%', maxHeight:'10%',  height:'100%',  maxWidth:isDesktop ? '50%' : '100%', }} />
          </div>
        </StyledCardContent>
        {/* </div> */}
        <StyledCardContent>
          <div className="smTitle flex-width">Review Payment</div>
          <div className="b-b m-t-14 m-b-14" />
          {localState?.OrderSelected?.map((el, i) => (
            <div className="smTitle flex-width m-b-8" key={i}>
              <div className="flex-space">
                <div className="xsTitle color-text-1">
                  {el.Description} x {el.Quantity}
                </div>
                <div className="desc color-text-2">
                  {dateFormat(new Date(), 'dd mmm yyyy')}
                </div>
              </div>
              <div className="xsTitle color-text-1 icon-text">
                <MonetizationOnIcon /> {amtStr(el.TotalItemPrice)}
              </div>
            </div>
          ))}
          <div className="b-b m-t-14 m-b-14" />
          <div className="flex-width  ">
            <span className="fs-14 fw-bold flex-space">Total</span>
            <span className="fs-14 fw-bold title icon-text">
              {' '}
              <MonetizationOnIcon /> {amtStr(TotalAmount)}
            </span>
          </div>
        </StyledCardContent>
      </ContentWrapper>
      {/* <StyledFooter
        sections={{
          option: [
            {
              props: {
                onClick: () => {
                  handleChargeToRoom('Room')
                },
                disabled: TotalAmount === 0,
              },

              name: 'Charge to Room',
            },
            {
              props: {
                onClick: () => {
                  handleOnlinePayment('Online')
                  dispatch({ type: 'OrderMode', payload: 'Online' })
                },
                disabled: TotalAmount === 0 || !IsPaymentGateWayHotel,
              },

              name: 'Pay Now',
            },
          ],
        }}
      /> */}
      <StyledFooter
        sections={{
          isSingle: true,
          option: [
            {
              props: {
                onClick: () =>  dispatch({ type: 'PaymentDialog', payload: {} }),
                disabled: TotalAmount === 0,
              },
              name: 'Confirm',
            },
          ],
        }}
      />
      <PaymentDialog
        state={state}
        dispatch={dispatch}
        ServicePaymentSetting={ServicePaymentSetting}
        IsPaymentGateWayHotel={IsPaymentGateWayHotel}
        ChargeToRoom={() => handleChargeToRoom('Room')}
        OnlinePayment={() => handleOnlinePayment('Online')}
      />
      <OrderDialog />
    </>
  )
}
