import {
  CommonDialog,
  ContentWrapper,
  Spinner,
  StyledCardContent,
  StyledFooter,
} from '@ifca-ui/neumorphism'
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  SvgIcon,
  TextField,
} from '@material-ui/core'

import { Refresh } from '@material-ui/icons'
import { Autocomplete } from '@material-ui/lab'
import { ReactComponent as CheckBoxIcon } from 'assets/svg/CheckBoxIcon.svg'
import AppContext, { AppContextProps } from 'containers/Context/Context'
import { IAction } from 'containers/Context/Types'
import { SERVICE_PATH } from 'containers/Module/Services/Routes'
import { b64toBlob } from 'containers/Utils/b64toBlob'
import { DialogMode } from 'containers/Utils/CommonTypes'
import { CountryEnum, NationalityEnum, StateEnum } from 'containers/Utils/Enum'
import dateFormat from 'dateformat'
import {
  Nationality,
  useCheckInMutation,
  usePaymentRegistrationListingLazyQuery,
} from 'generated/graphql'
import React, {
  FC,
  Reducer,
  useContext,
  useEffect,
  useReducer,
  useRef,
} from 'react'
import NumberFormat from 'react-number-format'
import { useHistory, useLocation } from 'react-router'
import SignaturePad from 'react-signature-canvas'
import { BOOKING_PATH } from '../Routes'

interface Props {
  Dialog?: {
    Open?: boolean
    Mode?: DialogMode
  }
  FullName?: string
  NRIC?: string
  Nationality?: string
  MobileNo?: string
  Email?: string
  Address?: {
    address?: string
    country?: string
    state?: string
    city?: string
    postCode?: string
  }
  IsTnc?: boolean
  SignatureBlob?: null | any
  IsSignature?: boolean
}
export const ERegistration: FC = () => {
  const { RootState, RootDispatch } = useContext<AppContextProps>(AppContext)
  const location = useLocation()
  const history = useHistory()
  const loggedinuser = JSON.parse(localStorage.getItem('GuestLoginInfo'))?.Contact
  console.log(loggedinuser,'loggedinuser')
  const localState = location.state as any
  console.log(localState,'localState')
  const ContactInfo = localState?.Data?.Guest?.Guest?.Contact
  const initialState: Props = {
    Dialog: {
      Open: false,
      Mode: '',
    },
    FullName:
      loggedinuser?.FullName ||
    
      '',
    NRIC:
      loggedinuser?.NRIC ||
     
      '',
    Nationality:
      loggedinuser?.Nationality ||
      ContactInfo?.Nationality ||
      '',
    MobileNo:
      loggedinuser?.MobileNo ||
  
      '',
    Email:
      loggedinuser?.Email ||

      '',
    Address: {
      address:
        loggedinuser?.Address?.address || 
        ContactInfo?.Address?.address ||
        '',
      country:
        loggedinuser?.Address?.country ||
        ContactInfo?.Address?.country ||
        '',
      state:
        loggedinuser?.Address?.state ||
        ContactInfo?.Address?.state ||
        '',
      city:
        loggedinuser?.Address?.city ||
        ContactInfo?.Address?.city ||
        '',
      postCode:
        loggedinuser?.Address?.postCode ||
        ContactInfo?.Address?.postCode ||
        '',
    },
    IsTnc: false,
    IsSignature: false,
    SignatureBlob: null,
  }

  const reducer: Reducer<Props, IAction> = (state, action) => {
    switch (action.type) {
      case 'OpenDialog':
        return { ...state, Dialog: { Open: true, Mode: '' } }
      case 'CloseDialog':
        return { ...state, Dialog: { Open: false, Mode: '' } }
      case 'Reset':
        return initialState
      default:
        return { ...state, [action.type]: action.payload }
    }
  }
  const [state, dispatch] = useReducer(reducer, initialState)
  const sigCanvas = useRef(null)
  const clear = () => {
    sigCanvas.current?.clear()
    dispatch({
      type: 'IsSignature',
      payload: false,
    })
  }
  const [CheckOutStandingPayment] = usePaymentRegistrationListingLazyQuery({
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      if (data.PaymentRegistrationListing.TaxLedger?.length > 0) {
        history.push(BOOKING_PATH.BOOKING_CHECKIN_SUMMARY, { ...localState })
      } else {
        history.push(SERVICE_PATH.LIST)
      }
    },
  })
  const [CheckIn, { loading }] = useCheckInMutation({
    onError: error => {
      error.graphQLErrors.map(({ message }) => {
        return RootDispatch({
          type: 'OnSnackBar',
          payload: {
            ...RootState.OnSnackBar,
            Open: true,
            Message: message,
            onClick: () =>
              RootDispatch({
                type: 'CloseSnackBar',
                payload: {},
              }),
          },
        })
      })
    },
    onCompleted: data => {
      if (data.CheckIn) {
        CheckOutStandingPayment({
          variables: {
            RegistrationID: localState?.Data?.Guest?.ID,
          },
        })
        RootDispatch({
          type: 'OnSnackBar',
          payload: {
            ...RootState.OnSnackBar,
            Open: true,
            Message: 'CheckIn Successfully',
          },
        })
        // history.push(BOOKING_PATH.BOOKING_CHECKIN_SUMMARY, { ...localState })
      }
    },
  })
  console.log(localState, 'here')
  const handleSubmit = () => {
    CheckIn({
      variables: {
        BookingID: localState?.Data?.BookingDetails?.ID,
        HotelID: localState?.Data?.BookingDetails?.HotelID,
        RegistrationIDs: [localState?.Data?.Guest?.ID],
        GuestAppCheckIn: true,
        AttachmentInput: state.SignatureBlob,
        IdPicture: null ,
        //  localState?.ScanInfo?.Blob,
        ContactInput: {
          ID: localState?.Data?.Guest?.Guest?.Contact?.ID,
          Nationality: Nationality[state.Nationality],
          FullName: state.FullName,
          MobileNo: state.MobileNo,
          NRIC: state.NRIC,
          Address: state.Address,
          GuestID: JSON.parse(localStorage.getItem('GuestLoginInfo'))?.ID,
          AccountID: JSON.parse(localStorage.getItem('GuestLoginInfo'))?.AccountID
        },
      },
    })
  }
  useEffect(() => {
    RootDispatch({
      type: 'HeaderSection',
      payload: {
        left: {
          icon: 'leftArrow',
          title: 'Check In',
          props: {
            onClick: () => history.goBack(),
            // history.push(BOOKING_PATH.BOOKING_DETAIL, { ...localState }),
          },
        },
      },
    })
  }, [])
  console.log(state.SignatureBlob,'test')
  useEffect(() => {
    if (sigCanvas.current) {
      var contentType = 'png'
      var wtf = sigCanvas.current?.getTrimmedCanvas().toDataURL('image/png')
      var img = wtf.slice(22)
      const blob = b64toBlob(img, contentType, 512)
      blob['name'] = 'Signature' + '.png'
      blob['lastModified'] = Date.UTC(
        dateFormat(new Date(), 'yyyy'),
        dateFormat(new Date(), 'mm'),
        dateFormat(new Date(), 'dd')
      )
      blob['lastModifiedDate'] = new Date()
      dispatch({
        type: 'SignatureBlob',
        payload: blob,
      })
    }
  }, [sigCanvas.current])


  const TncDialog = () => {
    return (
      <CommonDialog
        open={state.Dialog?.Open}
        onClose={() => {
          dispatch({
            type: 'CloseDialog',
            payload: {},
          })
        }}
        sections={{
          content: (
            <>
              <div className=" text-align-center color-blue-2 smTitle">
                Terms and Conditions
              </div>
              <div
                // className="desc "
                style={{
                  whiteSpace: 'break-spaces',
                  height: 400,
                }}
                dangerouslySetInnerHTML={{
                  __html: localState?.Data?.BookingDetails?.Hotel?.RegCardTnC,
                }}
              />
            </>
          ),
          footer: {
            children: (
              <div className="single-button">
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  className="action-btn"
                  onClick={() =>
                    dispatch({
                      type: 'CloseDialog',
                      payload: {},
                    })
                  }
                >
                  Cancel
                </Button>
              </div>
            ),
          },
        }}
      />
    )
  }
  return (
    <>
      {loading && <Spinner />}
      <ContentWrapper footer>
        <StyledCardContent>
          <TextField
            label="Name"
            margin="normal"
            fullWidth
            required
            value={state.FullName}
            onChange={e =>
              dispatch({
                type: 'FullName',
                payload: e.target.value,
              })
            }
          />
          <TextField
            label="Mobile No."
            value={state.MobileNo}
            margin="normal"
            fullWidth
            onChange={e =>
              dispatch({
                type: 'MobileNo',
                payload: e.target.value,
              })
            }
          />
          <TextField
            label="Email Address"
            value={state.Email}
            // error={CheckEmail}
            // helperText={CheckEmail && 'Please enter different email address'}
            margin="normal"
            fullWidth
            onChange={e =>
              dispatch({
                type: 'Email',
                payload: e.target.value,
              })
            }
          />
          <Grid container justify="flex-start">
            <Grid
              item
              xs={6}
              style={{
                paddingRight: '6px',
              }}
            >
              <Autocomplete
                fullWidth
                // className="left"
                options={NationalityEnum}
                getOptionLabel={option => option || ''}
                value={state.Nationality || ''}
                onChange={(_, value) => {
                  dispatch({
                    type: 'Nationality',
                    payload: value,
                  })
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    required
                    label="Nationality"
                    margin="normal"
                    style={{ width: '100%' }}
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                paddingLeft: '6px',
              }}
            >
              <NumberFormat
                customInput={TextField}
                required
                label={
                  state.Nationality?.toLowerCase() === 'malaysian'
                    ? 'NRIC No.'
                    : !state.Nationality
                    ? 'NRIC / Passport No.'
                    : 'Passport No.'
                }
                value={state.NRIC}
                fullWidth
                margin="normal"
                onValueChange={e => {
                  dispatch({
                    type: 'NRIC',
                    payload: e.value,
                  })
                }}
                format={
                  state.Nationality?.toLowerCase() === 'malaysian'
                    ? '######-##-####'
                    : null
                }
                mask={
                  state.Nationality?.toLowerCase() === 'malaysian' ? '_' : ''
                }
              />
            </Grid>
          </Grid>

          <TextField
            value={state.Address.address}
            multiline
            InputLabelProps={{
              shrink: true,
            }}
            rowsMax={6}
            onChange={e =>
              dispatch({
                type: 'Address',
                payload: {
                  ...state.Address,
                  address: e.target.value,
                },
              })
            }
            label="Address"
            margin="normal"
            fullWidth
          />
          <Grid container justify="flex-start">
            <Grid
              item
              xs={6}
              style={{
                paddingRight: '6px',
              }}
            >
              <Autocomplete
                fullWidth
                options={CountryEnum}
                getOptionLabel={option => option || ''}
                value={state.Address?.country || ''}
                onChange={(_, value) => {
                  dispatch({
                    type: 'Address',
                    payload: {
                      ...state.Address,
                      country: value,
                    },
                  })
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    required
                    label="Country"
                    margin="normal"
                    style={{ width: '100%' }}
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                paddingLeft: '6px',
              }}
            >
              {state.Address?.country?.toLowerCase() === 'malaysia' ? (
                <TextField
                  label="State"
                  fullWidth
                  select
                  required
                  margin="normal"
                  value={state.Address.state}
                  name="state"
                  onChange={e => {
                    dispatch({
                      type: 'Address',
                      payload: {
                        ...state.Address,
                        state: e.target.value,
                      },
                    })
                  }}
                >
                  {StateEnum.map(i => (
                    <MenuItem key={i} value={i}>
                      {i}
                    </MenuItem>
                  ))}
                </TextField>
              ) : (
                <TextField
                  label="State"
                  fullWidth
                  margin="normal"
                  required
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="state"
                  value={state.Address.state}
                  onChange={e => {
                    dispatch({
                      type: 'Address',
                      payload: {
                        ...state.Address,
                        state: e.target.value,
                      },
                    })
                  }}
                />
              )}
            </Grid>
          </Grid>
          <TextField
            label="City"
            fullWidth
            margin="normal"
            className="left"
            InputLabelProps={{
              shrink: true,
            }}
            value={state.Address?.city}
            onChange={e => {
              dispatch({
                type: 'Address',
                payload: {
                  ...state.Address,
                  city: e.target.value,
                },
              })
            }}
          />
          <TextField
            label="Postcode"
            fullWidth
            margin="normal"
            className="right"
            InputLabelProps={{
              shrink: true,
            }}
            value={state.Address?.postCode}
            onChange={e => {
              dispatch({
                type: 'Address',
                payload: {
                  ...state.Address,
                  postCode: e.target.value,
                },
              })
            }}
          />
          <FormControlLabel
            style={{ marginRight: '0px' }}
            control={
              <Checkbox
                value={state.IsTnc}
                color="primary"
                onChange={e => {
                  e.stopPropagation()
                  dispatch({
                    type: 'IsTnc',
                    payload: !state.IsTnc,
                  })
                }}
                checkedIcon={
                  <SvgIcon component={CheckBoxIcon} viewBox="0 0 30 30" />
                }
              />
            }
            label={
              <div className="desc fw-medium">
                I ackowledge and accept{' '}
                <span
                  className="color-blue-1 click-text"
                  onClick={e => {
                    e.stopPropagation()
                    dispatch({
                      type: 'OpenDialog',
                      payload: {},
                    })
                  }}
                >
                  Terms and Condition
                </span>
              </div>
            }
            labelPlacement="end"
          />
          <div className="">
            <div
              style={{
                position: 'absolute',
                right: '50px',
              }}
              className="click-text color-blue m-t-4"
              onClick={clear}
            >
              <span className="desc icon-text color-blue-2">
                <Refresh /> Clear
              </span>
            </div>
            <SignaturePad
              // {...props}
              ref={sigCanvas}
              onBegin={() => dispatch({ type: 'IsSignature', payload: false })}
              onEnd={() => dispatch({ type: 'IsSignature', payload: true })}
              backgroundColor="#efefef"
              canvasProps={{
                className: 'm-b-10',
                style: {
                  width: '100%',
                  height: 150,
                  borderRadius: '10px',
                  border: '1px solid #c2c2c2',
                },
              }}
            />
          </div>
        </StyledCardContent>
      </ContentWrapper>
      <StyledFooter
        sections={{
          isSingle: true,
          option: [
            {
              props: {
                onClick: () => handleSubmit(),
                disabled:
                  !state.Nationality ||
                  !state.NRIC ||
                  !state.Address?.country ||
                  !state.Address?.state ||
                  !state.FullName ||
                  !state.IsTnc ||
                  !state.IsSignature,
              },
              name: 'Proceed',
            },
          ],
        }}
      />
      <TncDialog />
    </>
  )
}
