import {
  CommonDialog,
  ContentWrapper,
  Spinner,
  StyledCardContent,
} from '@ifca-ui/neumorphism'
import {
  Avatar,
  Badge,
  Button,
  Grid,
  List,
  Fab,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@material-ui/core'
import Modal from '@material-ui/core/Modal'
import AccessTimeOutlinedIcon from '@material-ui/icons/AccessTimeOutlined'
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn'
import DefaultImg from 'assets/images/default-img.png'
import AddressIcon from 'assets/svg/account/address.svg'
import CallIcon from 'assets/svg/account/call.svg'
import MailIcon from 'assets/svg/account/email.svg'

import PhoneNoIcon from 'assets/svg/account/phone-no.svg'

import { ReactComponent as WazeIcon } from 'assets/svg/account/waze.svg'
import { ReactComponent as GoogleMapIcon } from 'assets/svg/account/google-map.svg'
import BillIcon from 'assets/svg/service/bill.svg'
import BookingIcon from 'assets/svg/service/booking.svg'
import CheckOutIcon from 'assets/svg/service/checkout.svg'
import DirectIcon from 'assets/svg/service/directories.svg'
import DoorIcon from 'assets/svg/service/door.svg'
import ExtendStayIcon from 'assets/svg/service/extend-stay.svg'
import LateCheckoutIcon from 'assets/svg/service/late-checkout.svg'
import MyReqIcon from 'assets/svg/service/my-request.svg'
import PackagesRedIcon from 'assets/svg/service/package-redemption.svg'
import ScanIcon from 'assets/svg/service/scan.svg'
import DurationIcon from 'assets/svg/stay-duration.svg'
import AppContext, { AppContextProps } from 'containers/Context/Context'
import { IAction } from 'containers/Context/Types'
import { useMenuOption } from 'containers/hooks/useMenuOption'
import { DialogMode } from 'containers/Utils/CommonTypes'
import { amtStr } from 'containers/Utils/numFormatter'
import dateFormat from 'dateformat'
import AddressConverter from './addressConverter'
import {
  useGaInHouseGuestListingQuery,
  useGaMarketingAdsListingLazyQuery,
  useGuestAppFeatureListQuery,
  useGuestMovementInsertMutation,
  usePackageRedemptionListingLazyQuery,
  usePaymentUpdateMutation,
  // useIsHotelLockUserQuery,
  useExtendStayInsertMutation,
  useLateCheckOutInsertMutation,
  useGuestRoomServiceInsertMutation,
  useFrontDeskPaymentInsertMutation
} from 'generated/graphql'
import queryString from 'query-string'
import React, { FC, Reducer, useContext, useEffect, useReducer } from 'react'
import QrReader from 'react-qr-reader'
import { useHistory, useLocation } from 'react-router'
import SwiperCore, { Pagination } from 'swiper/core'
import { Swiper, SwiperSlide } from 'swiper/react'
import { AccountMenuOption } from '../Account/AccountMenuOption'
import { PUBLIC_PATH } from '../Auth/Routes'
import { CheckOutDialog } from './CheckOutDialog'
import { RequestDrawer } from './RequestDrawer'
import { SERVICE_PATH } from './Routes'
import { BOOKING_PATH } from '../Booking/Routes'

// install Swiper modules
SwiperCore.use([Pagination])
interface ServicesProps {
  Drawer: boolean
  Dialog: {
    Open: boolean
    Mode: DialogMode
  }
  QRScannerModal: boolean
  CheckOutDialog?: boolean
  PasscodeDialog?: boolean
  QrScanner?: null | any
  MarketingAdsDialog?: {
    Open: boolean
    Mode: DialogMode
  }
  MarketingAdsIndex?: number
}
export const Services: FC = () => {
  const history = useHistory()
  const location = useLocation()

  const { RootState, RootDispatch } = useContext<AppContextProps>(AppContext)
  const { anchorEl, handleClose, handleClick } = useMenuOption()
  const PaymentInfo = queryString.parse(location.search)
  const [ExtendStayInsert, { loading:loadingextend }] = useExtendStayInsertMutation({})
  const {
    refetch,
    loading,
    data: { GAInHouseGuestListing } = { GAInHouseGuestListing: [] },
  } = useGaInHouseGuestListingQuery({
    variables: {
      GuestID: JSON.parse(localStorage.getItem('GuestLoginInfo'))?.ID,
    },
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      if (data.GAInHouseGuestListing?.length === 0) {
        history.push(BOOKING_PATH.ROOT)
      } else {
        return console.log('enjoy our services')
      }
    },
    onError: error => {
      // eslint-disable-next-line array-callback-return
      error.graphQLErrors.map(({ message }) => {
        RootDispatch({
          type: 'OnSnackBar',
          payload: {
            ...RootState.OnSnackBar,
            Open: true,
            Message:
              message === "Session expired, please login again."
                ? 'Token has expired.'
                : message,
            onClick: () =>
              RootDispatch({
                type: 'CloseSnackBar',
                payload: {},
              }),
          },
        })
        if (message === "Session expired, please login again.") {
          setTimeout(() => {
            RootDispatch({
              type: 'CloseSnackBar',
              payload: {},
            })
            history.push(PUBLIC_PATH.NOT_FOUND_404)
            localStorage.clear()
          }, 2000)
        }
      })
    },
  })
  const initialState: ServicesProps = {
    Drawer: false,
    QRScannerModal: false,
    CheckOutDialog: false,
    PasscodeDialog : false,
    Dialog: {
      Open: false,
      Mode: '',
    },
    QrScanner: null,
    MarketingAdsDialog: {
      Open: false,
      Mode: '',
    },
    MarketingAdsIndex: 0,
  }
  const reducer: Reducer<ServicesProps, IAction> = (state, action) => {
    switch (action.type) {
      case 'HotelDialog':
        return { ...state, Dialog: { Open: true, Mode: 'View' } }
      case 'AdsDialog':
        return { ...state, MarketingAdsDialog: { Open: true, Mode: 'View' } }
      case 'CloseMarketingAdsDialog':
        return { ...state, MarketingAdsDialog: { Open: false, Mode: '' } }
      case 'CloseDialog':
        return { ...state, Dialog: { Open: false, Mode: '' } }
      case 'Reset':
        return initialState
      default:
        return { ...state, [action.type]: action.payload }
    }
  }
  const [state, dispatch] = useReducer(reducer, initialState)
  const BookingDetails: any = GAInHouseGuestListing[0]?.Booking
  const HotelDetails: any = GAInHouseGuestListing[0]?.Booking?.Hotel
  const HotelCheckOutDate = dateFormat(HotelDetails?.CheckOutTime, 'hh:MM TT')
  const DirectoriesCount: number =
    GAInHouseGuestListing[0]?.Booking?.Hotel?.Facility?.length
    
  const HotelAddress: string = `${GAInHouseGuestListing[0]?.Booking?.Hotel?.HotelAddress.address}, ${GAInHouseGuestListing[0]?.Booking?.Hotel?.HotelAddress.city}, ${GAInHouseGuestListing[0]?.Booking?.Hotel?.HotelAddress.state}. ${GAInHouseGuestListing[0]?.Booking?.Hotel?.HotelAddress.postCode}`
  let address = AddressConverter({
    address: GAInHouseGuestListing[0]?.Booking?.Hotel?.HotelAddress?.address,
    address2: GAInHouseGuestListing[0]?.Booking?.Hotel?.HotelAddress?.address2,
    city: GAInHouseGuestListing[0]?.Booking?.Hotel?.HotelAddress?.city,
    country: GAInHouseGuestListing[0]?.Booking?.Hotel?.HotelAddress?.country,
    postCode: GAInHouseGuestListing[0]?.Booking?.Hotel?.HotelAddress?.postCode,
    state: GAInHouseGuestListing[0]?.Booking?.Hotel?.HotelAddress?.state,
  })
  const RoomDetails: any = GAInHouseGuestListing[0]?.Room
  const Passcode: any = GAInHouseGuestListing[0]?.PassCode ? JSON.parse(GAInHouseGuestListing[0]?.PassCode) : ''
  const RegistrationDetails: any = GAInHouseGuestListing[0]
  const MyRequestCount: number =
    RegistrationDetails?.IncidentalChargesCount +
    RegistrationDetails?.ServiceRequestCount
  const LateCheckOutTime: any =
    dateFormat(RegistrationDetails?.DepartureDate, 'hh:MM TT') !==
    dateFormat(RegistrationDetails?.CheckOutDate, 'hh:MM TT')
      ? dateFormat(RegistrationDetails?.DepartureDate, 'hh:MM TT')
      : HotelCheckOutDate
  const CheckInDate = dateFormat(
    RegistrationDetails?.CheckInDate,
    'dd mmm yyyy'
  )
  const CheckOutDate = dateFormat(
    GAInHouseGuestListing[0]?.CheckOutDate,
    'dd mmm yyyy'
  )
  const [LateCheckOutInsert, { loading:loadinglatecheckout }] = useLateCheckOutInsertMutation({})
  const [
    FrontDeskPaymentInsert,
    { loading: InsertLoading },
  ] = useFrontDeskPaymentInsertMutation({})
  const TotalOutStandingAmount = RegistrationDetails?.TotalOutStandingAmount
  const {
    loading: GuestAppFeatureListLoading,
    data: { GuestAppFeatureList } = { GuestAppFeatureList: null },
  } = useGuestAppFeatureListQuery({
    variables: {
      HotelID: HotelDetails?.ID,
    },
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      if (data.GuestAppFeatureList?.ID !== '') {
        // history.push(BOOKING_PATH.BOOKING_LIST)
      } else {
        return console.log('enjoy our services')
      }
    },
    onError: error => {
      // eslint-disable-next-line array-callback-return
      error.graphQLErrors.map(({ message }) => {
        RootDispatch({
          type: 'OnSnackBar',
          payload: {
            ...RootState.OnSnackBar,
            Open: true,
            Message:
              message === "Session expired, please login again."
                ? 'Token Expired'
                : message,
            onClick: () =>
              RootDispatch({
                type: 'CloseSnackBar',
                payload: {},
              }),
          },
        })
        if (message === "Session expired, please login again.") {
          setTimeout(() => {
            RootDispatch({
              type: 'CloseSnackBar',
              payload: {},
            })
            history.push(PUBLIC_PATH.NOT_FOUND_404)
            localStorage.clear()
          }, 2000)
        }
      })
    },
  })
  console.log(GuestAppFeatureList, 'GuestAppFeatureList')
  const handleProgressBarPercent = () => {
    let startDate = new Date(RegistrationDetails?.ArrivalDate).getTime()
    let endDate = new Date(RegistrationDetails?.DepartureDate).getTime()
    let todayDate = new Date().getTime()

    let total = endDate - startDate

    let current = todayDate - startDate

    return (current / total) * 100
  }
  const [
    PackgeRedemQuery,
    { data: { PackageRedemptionListing } = { PackageRedemptionListing: [] } },
  ] = usePackageRedemptionListingLazyQuery({
    fetchPolicy: 'no-cache',
  })
  const [
    MarketingAdsQuery,
    { data: { GAMarketingAdsListing } = { GAMarketingAdsListing: [] } },
  ] = useGaMarketingAdsListingLazyQuery({
    fetchPolicy: 'no-cache',
  })
  const handleScan = data => {
    if (data) {
      dispatch({
        type: 'QrScanner',
        payload: data,
      })
    }
  }
  const TotalPax = PackageRedemptionListing?.reduce((acc, curr) => {
    return acc + curr.Total
  }, 0)
  const TotalRedeemed = PackageRedemptionListing?.reduce((acc, curr) => {
    return acc + curr.Redeemed
  }, 0)
  const handleError = err => {
    console.log(err)
  }
  const [
    GuestRoomServiceInsert,
    { loading:loadingservicereq },
  ] = useGuestRoomServiceInsertMutation({})
  const [PaymentUpdate] = usePaymentUpdateMutation()
  const [GuestMovementInsert] = useGuestMovementInsertMutation({})
  useEffect(() => {
    if (state.QrScanner) {
      dispatch({ type: 'QRScannerModal', payload: false })
      GuestMovementInsert({
        variables: {
          GuestMovementInput: {
            GuestID: JSON.parse(localStorage.getItem('GuestLoginInfo'))?.ID,
            HotelID: state.QrScanner?.split('/')[0],
            BookingID: BookingDetails?.ID,
            RefID: state.QrScanner?.split('/')[1],
            Type: state.QrScanner?.split('/')[2],
          },
        },
      }).then(i => {
        if (i.data.GuestMovementInsert) {
          dispatch({ type: 'QRScannerModal', payload: false })
          RootDispatch({
            type: 'OnSnackBar',
            payload: {
              ...RootState.OnSnackBar,
              Open: true,
              Message: 'Check in Successfully',
            },
          })
        } else {
          RootDispatch({
            type: 'OnSnackBar',
            payload: {
              ...RootState.OnSnackBar,
              Open: true,
              Message: 'Check in Fail',
            },
          })
        }
      })
    }
  }, [state.QrScanner])
  // const {
  //   data: { IsHotelLockUser } = { IsHotelLockUser: false },
  // } = useIsHotelLockUserQuery({
  //   variables: { HotelID: HotelDetails?.ID },
  //   fetchPolicy: 'no-cache',
  // })
  useEffect(()=>{
    if(TotalOutStandingAmount === 0 && dateFormat(RegistrationDetails?.DepartureDate,'dd-mm-yyyy')
    === dateFormat(new Date(),'dd-mm-yyyy')){
    dispatch({ type: 'CheckOutDialog', payload: true })
    }
  },[TotalOutStandingAmount])


  useEffect(() => {
    if (PaymentInfo?.orderId) {

      if(localStorage.getItem('extendstay') && PaymentInfo.status as string === 'SUCCESS'){
        ExtendStayInsert({
          variables: JSON.parse(localStorage.getItem('extendstay')),
        }).then(ie => {
          if (ie.data.ExtendStayInsert !== 'fail') {
         
            localStorage.setItem('FDReceiptNo',ie.data.ExtendStayInsert.ReceiptNo )
            PaymentUpdate({
              variables: {
                CheckOutID: localStorage.getItem('CheckOutID'),
                AppName:'loyalty-app',
                IsGuestApp:
                  localStorage.getItem('isRoomService') === 'true' ? true : false,
                OrderID: PaymentInfo.orderId as string,
                ReceiptNo: ie.data.ExtendStayInsert.ReceiptNo ,
                HotelID: localStorage.getItem('HotelID'),
                Status:
                  (PaymentInfo.status as string)?.charAt(0).toUpperCase() +
                  (PaymentInfo.status as string)?.slice(1).toLowerCase()
              },
              onCompleted: data => {
                if (data.PaymentUpdate) {
                  let Status = PaymentInfo.status === 'CANCELLED' ? 'Cancelled Successfully' : 
                  PaymentInfo.status === 'EXPIRED' ? 'Session Expired' : 
                  'Payment Successfully'
                  RootDispatch({
                    type: 'OnSnackBar',
                    payload: {
                      ...RootState.OnSnackBar,
                      Open: true,
                      Message: `${Status}`,
                      onClick: () =>
                        RootDispatch({
                          type: 'CloseSnackBar',
                          payload: {},
                        }),
                    },
                  })

                  RootDispatch({
                    type: 'OnSnackBar',
                    payload: {
                      ...RootState.OnSnackBar,
                      Open: true,
                      Message: 
                      // IsHotelLockUser === true ? "Password validity extended succesfully" :
                      'Extend Stay Successfully',
                    },
                  })
                  localStorage.removeItem('FDReceiptNo')
                  localStorage.removeItem('LocalState')
                  localStorage.removeItem('isRoomService')
                  localStorage.removeItem('HotelID')
                  localStorage.removeItem('CheckOutID')
                  refetch()
                } else {
                  RootDispatch({
                    type: 'OnSnackBar',
                    payload: {
                      ...RootState.OnSnackBar,
                      Open: true,
                      Message: 'Payment Fail',
                      onClick: () =>
                        RootDispatch({
                          type: 'CloseSnackBar',
                          payload: {},
                        }),
                    },
                  })
           
                }
              },
              onError: error => {
                error.graphQLErrors?.map(({ message }) => {
                  RootDispatch({
                    type: 'OnSnackBar',
                    payload: {
                      ...RootState.OnSnackBar,
                      Open: true,
                      Message: message === "Empty criteria(s) are not allowed for the update method." ? 
                      "Payment has been cancelled" : message,
                      onClick: () =>
                        RootDispatch({
                          type: 'CloseSnackBar',
                          payload: {},
                        }),
                    },
                  })
                })
                localStorage.removeItem('FDReceiptNo')
                localStorage.removeItem('CheckOutID')
                localStorage.removeItem('HotelID')
                localStorage.removeItem('isRoomService')
              },
            })
            localStorage.removeItem('extendstay')
          
          } else {
            RootDispatch({
              type: 'OnSnackBar',
              payload: {
                ...RootState.OnSnackBar,
                Open: true,
                Message: 'Extend Stay Fail',
              },
            })
          }
        })
      }

      else if(localStorage.getItem('latecheckout') && PaymentInfo.status as string === 'SUCCESS'){
        LateCheckOutInsert({
          variables: JSON.parse(localStorage.getItem('latecheckout')),
        }).then(ie => {
          if (ie.data.LateCheckOutInsert !== 'fail') {
            localStorage.setItem('FDReceiptNo', ie.data.LateCheckOutInsert)
            PaymentUpdate({
              variables: {
                CheckOutID: localStorage.getItem('CheckOutID'),
                AppName:'loyalty-app',
                IsGuestApp:
                  localStorage.getItem('isRoomService') === 'true' ? true : false,
                OrderID: PaymentInfo.orderId as string,
                ReceiptNo: ie.data.LateCheckOutInsert ,
                HotelID: localStorage.getItem('HotelID'),
                Status:
                  (PaymentInfo.status as string)?.charAt(0).toUpperCase() +
                  (PaymentInfo.status as string)?.slice(1).toLowerCase()
              },
              onCompleted: data => {
                if (data.PaymentUpdate) {
                  let Status = PaymentInfo.status === 'CANCELLED' ? 'Cancelled Successfully' : 
                  PaymentInfo.status === 'EXPIRED' ? 'Session Expired' : 
                  'Payment Successfully'
                  RootDispatch({
                    type: 'OnSnackBar',
                    payload: {
                      ...RootState.OnSnackBar,
                      Open: true,
                      Message: `${Status}`,
                      onClick: () =>
                        RootDispatch({
                          type: 'CloseSnackBar',
                          payload: {},
                        }),
                    },
                  })
                  refetch()
                  RootDispatch({
                    type: 'OnSnackBar',
                    payload: {
                      ...RootState.OnSnackBar,
                      Open: true,
                      Message: 
                      // IsHotelLockUser === true ? "Password validity extended succesfully" :
                      'Late CheckOut Successfully',
                    },
                  })
                  localStorage.removeItem('FDReceiptNo')
                  localStorage.removeItem('LocalState')
                  localStorage.removeItem('isRoomService')
                  localStorage.removeItem('HotelID')
                  localStorage.removeItem('CheckOutID')
               
                } else {
                  RootDispatch({
                    type: 'OnSnackBar',
                    payload: {
                      ...RootState.OnSnackBar,
                      Open: true,
                      Message: 'Payment Fail',
                      onClick: () =>
                        RootDispatch({
                          type: 'CloseSnackBar',
                          payload: {},
                        }),
                    },
                  })
                
                }
              },
              onError: error => {
                error.graphQLErrors?.map(({ message }) => {
                  RootDispatch({
                    type: 'OnSnackBar',
                    payload: {
                      ...RootState.OnSnackBar,
                      Open: true,
                      Message: message === "Empty criteria(s) are not allowed for the update method." ? 
                      "Payment has been cancelled" : message,
                      onClick: () =>
                        RootDispatch({
                          type: 'CloseSnackBar',
                          payload: {},
                        }),
                    },
                  })
                })
                localStorage.removeItem('FDReceiptNo')
                localStorage.removeItem('CheckOutID')
                localStorage.removeItem('HotelID')
                localStorage.removeItem('isRoomService')
              },
            })
            localStorage.removeItem('latecheckout')
            
          } else {
            RootDispatch({
              type: 'OnSnackBar',
              payload: {
                ...RootState.OnSnackBar,
                Open: true,
                Message: 'Late Checkout Fail',
              },
            })
          }
        })
      }

      else if(localStorage.getItem('servicerequest') && PaymentInfo.status as string === 'SUCCESS'){
        GuestRoomServiceInsert({
          variables: JSON.parse(localStorage.getItem('servicerequest')),
        }).then(ie => {
          if (ie.data.GuestRoomServiceInsert !== 'Fail') {
           
            PaymentUpdate({
              variables: {
                CheckOutID: localStorage.getItem('CheckOutID'),
                AppName:'loyalty-app',
                IsGuestApp:
                  localStorage.getItem('isRoomService') === 'true' ? true : false,
                OrderID: PaymentInfo.orderId as string,
                ReceiptNo: ie.data.GuestRoomServiceInsert ,
                HotelID: localStorage.getItem('HotelID'),
                Status:
                  (PaymentInfo.status as string)?.charAt(0).toUpperCase() +
                  (PaymentInfo.status as string)?.slice(1).toLowerCase()
              },
              onCompleted: data => {
                if (data.PaymentUpdate) {
                  let Status = PaymentInfo.status === 'CANCELLED' ? 'Cancelled Successfully' : 
                  PaymentInfo.status === 'EXPIRED' ? 'Session Expired' : 
                  'Payment Successfully'
                  RootDispatch({
                    type: 'OnSnackBar',
                    payload: {
                      ...RootState.OnSnackBar,
                      Open: true,
                      Message: `${Status}`,
                      onClick: () =>
                        RootDispatch({
                          type: 'CloseSnackBar',
                          payload: {},
                        }),
                    },
                  })
                  refetch()
                  RootDispatch({
                    type: 'OnSnackBar',
                    payload: {
                      ...RootState.OnSnackBar,
                      Open: true,
                      Message: 'Payment Successfully',
                    },
                  })
                  localStorage.removeItem('FDReceiptNo')
                  localStorage.removeItem('LocalState')
                  localStorage.removeItem('isRoomService')
                  localStorage.removeItem('HotelID')
                  localStorage.removeItem('CheckOutID')
               
                } else {
                  RootDispatch({
                    type: 'OnSnackBar',
                    payload: {
                      ...RootState.OnSnackBar,
                      Open: true,
                      Message: 'Payment Fail',
                      onClick: () =>
                        RootDispatch({
                          type: 'CloseSnackBar',
                          payload: {},
                        }),
                    },
                  })
                
                }
              },
              onError: error => {
                error.graphQLErrors?.map(({ message }) => {
                  RootDispatch({
                    type: 'OnSnackBar',
                    payload: {
                      ...RootState.OnSnackBar,
                      Open: true,
                      Message: message === "Empty criteria(s) are not allowed for the update method." ? 
                      "Payment has been cancelled" : message,
                      onClick: () =>
                        RootDispatch({
                          type: 'CloseSnackBar',
                          payload: {},
                        }),
                    },
                  })
                })
                localStorage.removeItem('FDReceiptNo')
                localStorage.removeItem('CheckOutID')
                localStorage.removeItem('HotelID')
                localStorage.removeItem('isRoomService')
              },
            })
            localStorage.removeItem('servicerequest')
          } else {
            RootDispatch({
              type: 'OnSnackBar',
              payload: {
                ...RootState.OnSnackBar,
                Open: true,
                Message: 'Order Fail',
              },
            })
          }
        })
      }
      else if(localStorage.getItem('frontdeskpayment') && PaymentInfo.status as string === 'SUCCESS'){
      FrontDeskPaymentInsert({
        variables: {
          TotalPaymentAmount: JSON.parse(localStorage.getItem('frontdeskpayment'))?.TotalPaymentAmount,
          input: JSON.parse(localStorage.getItem('frontdeskpayment'))?.input,
          AppName:'loyalty-app',
        },
      }).then(ie => {
        if (ie.data.FrontDeskPaymentInsert) {
          PaymentUpdate({
            variables: {
              CheckOutID: localStorage.getItem('CheckOutID'),
              AppName:'loyalty-app',
              IsGuestApp:
                localStorage.getItem('isRoomService') === 'true' ? true : false,
              OrderID: PaymentInfo.orderId as string,
              ReceiptNo: ie.data.FrontDeskPaymentInsert.ReceiptNo ,
              HotelID: localStorage.getItem('HotelID'),
              Status:
                (PaymentInfo.status as string)?.charAt(0).toUpperCase() +
                (PaymentInfo.status as string)?.slice(1).toLowerCase()
            },
            onCompleted: data => {
              if (data.PaymentUpdate) {
                let Status = PaymentInfo.status === 'CANCELLED' ? 'Cancelled Successfully' : 
                PaymentInfo.status === 'EXPIRED' ? 'Session Expired' : 
                'Payment Successfully'
                RootDispatch({
                  type: 'OnSnackBar',
                  payload: {
                    ...RootState.OnSnackBar,
                    Open: true,
                    Message: `${Status}`,
                    onClick: () =>
                      RootDispatch({
                        type: 'CloseSnackBar',
                        payload: {},
                      }),
                  },
                })
                refetch()
                RootDispatch({
                  type: 'OnSnackBar',
                  payload: {
                    ...RootState.OnSnackBar,
                    Open: true,
                    Message: 'Payment Successfully',
                  },
                })
                localStorage.removeItem('FDReceiptNo')
                localStorage.removeItem('LocalState')
                localStorage.removeItem('isRoomService')
                localStorage.removeItem('HotelID')
                localStorage.removeItem('CheckOutID')
             
              } else {
                RootDispatch({
                  type: 'OnSnackBar',
                  payload: {
                    ...RootState.OnSnackBar,
                    Open: true,
                    Message: 'Payment Fail',
                    onClick: () =>
                      RootDispatch({
                        type: 'CloseSnackBar',
                        payload: {},
                      }),
                  },
                })
              
              }
            },
            onError: error => {
              error.graphQLErrors?.map(({ message }) => {
                RootDispatch({
                  type: 'OnSnackBar',
                  payload: {
                    ...RootState.OnSnackBar,
                    Open: true,
                    Message: message === "Empty criteria(s) are not allowed for the update method." ? 
                    "Payment has been cancelled" : message,
                    onClick: () =>
                      RootDispatch({
                        type: 'CloseSnackBar',
                        payload: {},
                      }),
                  },
                })
              })
              localStorage.removeItem('FDReceiptNo')
              localStorage.removeItem('CheckOutID')
              localStorage.removeItem('HotelID')
              localStorage.removeItem('isRoomService')
            },
          })
          localStorage.removeItem('frontdeskpayment')
        } else {
          RootDispatch({
            type: 'OnSnackBar',
            payload: {
              ...RootState.OnSnackBar,
              Open: true,
              Message: 'Payment Fail',
            },
          })
        }
      })
    }
      else if( PaymentInfo.status as string === 'CANCELLED'){
        let Status = 'Cancelled Successfully' 
  
        PaymentUpdate({
          variables: {
            CheckOutID: localStorage.getItem('CheckOutID'),
            AppName:'loyalty-app',
            IsGuestApp:
              localStorage.getItem('isRoomService') === 'true' ? true : false,
            OrderID: PaymentInfo.orderId as string,
            ReceiptNo: localStorage.getItem('FDReceiptNo') ,
            HotelID: localStorage.getItem('HotelID'),
            Status:
              (PaymentInfo.status as string)?.charAt(0).toUpperCase() +
              (PaymentInfo.status as string)?.slice(1).toLowerCase()
          },
          onCompleted: data => {
            if (data.PaymentUpdate) {
              let Status = PaymentInfo.status === 'CANCELLED' ? 'Cancelled Successfully' : 
              PaymentInfo.status === 'EXPIRED' ? 'Session Expired' : 
              'Payment Successfully'
              RootDispatch({
                type: 'OnSnackBar',
                payload: {
                  ...RootState.OnSnackBar,
                  Open: true,
                  Message: `${Status}`,
                  onClick: () =>
                    RootDispatch({
                      type: 'CloseSnackBar',
                      payload: {},
                    }),
                },
              })
             
             
              localStorage.removeItem('FDReceiptNo')
              localStorage.removeItem('LocalState')
              localStorage.removeItem('isRoomService')
              localStorage.removeItem('HotelID')
              localStorage.removeItem('CheckOutID')
            } else {
              RootDispatch({
                type: 'OnSnackBar',
                payload: {
                  ...RootState.OnSnackBar,
                  Open: true,
                  Message: 'Payment Fail',
                  onClick: () =>
                    RootDispatch({
                      type: 'CloseSnackBar',
                      payload: {},
                    }),
                },
              })
            
            }
          },
          onError: error => {
            error.graphQLErrors?.map(({ message }) => {
              RootDispatch({
                type: 'OnSnackBar',
                payload: {
                  ...RootState.OnSnackBar,
                  Open: true,
                  Message: message === "Empty criteria(s) are not allowed for the update method." ? 
                  "Payment has been cancelled" : message,
                  onClick: () =>
                    RootDispatch({
                      type: 'CloseSnackBar',
                      payload: {},
                    }),
                },
              })
            })
            localStorage.removeItem('FDReceiptNo')
            localStorage.removeItem('CheckOutID')
            localStorage.removeItem('HotelID')
            localStorage.removeItem('isRoomService')
            localStorage.removeItem('extendstay')
            localStorage.removeItem('latecheckout')
          },
        })
      }
     

    }
  }, [])

  useEffect(() => {
    RootDispatch({
      type: 'HeaderSection',
      payload: {
        left: {
          icon: 'hotellogo',
          title: HotelDetails?.HotelName,
          Datalogo : HotelDetails?.LogoImage?.ImageURL,
          DataAddress: address,
          Isbutton:false,
          props: {
            // onClick: () => dispatch({ type: 'HotelDialog', payload: {} }),
          },
        },
        right: {
          icon: 'more',
          props: {
            onClick: (e: any) => handleClick(e),
            'aria-controls': 'customized-menu',
          },
        },
      },
    })
    // to prevent user click back button in browser
    // https://stackoverflow.com/questions/57655861/prevent-browser-back-button-for-a-specific-page-only-in-react
    if (location.pathname === '/services') {
      window.history.pushState(null, document.title, window.location.href)
      window.addEventListener('popstate', event => {
        window.history.pushState(null, document.title, window.location.href)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [HotelDetails])
  useEffect(() => {
    if (GAInHouseGuestListing?.length > 0) {
      PackgeRedemQuery({
        variables: {
          HotelID: HotelDetails?.ID,
          RegistrationID: RegistrationDetails?.ID,
        },
      })
      MarketingAdsQuery({
        variables: {
          HotelID: HotelDetails?.ID,
          isLoyaltyApp:false // inhouse already
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [HotelDetails, RegistrationDetails])

  const HeaderMenu = (
    <AccountMenuOption anchorEl={anchorEl} handleClose={handleClose} 
    HotelDetails={HotelDetails}
    HotelAddress={HotelAddress}
    
    />
  )

  console.log(state,Passcode,'test')
  const QuickAccessList = [
    {
      icon: ScanIcon,
      label: 'Door',
      props: {
        onClick:() => dispatch({ type: 'PasscodeDialog', payload: true }),
        disabled: GuestAppFeatureList?.DigitalKey
      },
    },
    // {
    //   icon: ScanIcon,
    //   label: 'Scan',
    //   props: {
    //     onClick: () => dispatch({ type: 'QRScannerModal', payload: true }),
    //     disabled: GuestAppFeatureList?.ScanQR,
    //   },
    // },
    {
      icon: DirectIcon,
      label: 'Directories',
      value: DirectoriesCount,
      props: {
        onClick: () =>
          history.push(SERVICE_PATH.DIRECTORIES, {
            ...HotelDetails,
          }),
        disabled: GuestAppFeatureList?.Directories,
      },
    },
    {
      icon: BookingIcon,
      label: 'Checkout',
      props: {
        onClick: () => {
          if (TotalOutStandingAmount > 0) {
            history.push(SERVICE_PATH.CHECKOUT_BILLS, {
              Hotel: HotelDetails,
              Registration: RegistrationDetails,
              TotalOutStandingAmount: TotalOutStandingAmount,
              isCheckOutset: GuestAppFeatureList?.CheckOut
            })
            // RootDispatch({
            //   type: 'OnSnackBar',
            //   payload: {
            //     ...RootState.OnSnackBar,
            //     Open: true,
            //     Message: `Outstanding Amount $${amtStr(
            //       TotalOutStandingAmount
            //     )}, Please proceed to payment.`,
            //   },
            // })
          } else {
            dispatch({ type: 'CheckOutDialog', payload: true })
          }
        },
        disabled: GuestAppFeatureList?.CheckOut,
      },
    },
  ]
  console.log(QuickAccessList,'QuickAccessList')
  const ServicesList = [
    {
      Icon: BookingIcon,
      Title: 'Checkout',
      Info: (
        <>
          <span className="fw-medium m-r-4">Checkout {dateFormat(RegistrationDetails?.CheckOutDate || RegistrationDetails?.DepartureDate,'dd mmm yyyy hh:mm TT')}</span> 
        </>
      ),
      props: {
        onClick: () => {
          if (TotalOutStandingAmount > 0) {
            history.push(SERVICE_PATH.CHECKOUT_BILLS, {
              Hotel: HotelDetails,
              Registration: RegistrationDetails,
              TotalOutStandingAmount: TotalOutStandingAmount,
              isCheckOutset: GuestAppFeatureList?.CheckOut
            })
            // RootDispatch({
            //   type: 'OnSnackBar',
            //   payload: {
            //     ...RootState.OnSnackBar,
            //     Open: true,
            //     Message: `Outstanding Amount $${amtStr(
            //       TotalOutStandingAmount
            //     )}, Please proceed to payment.`,
            //   },
            // })
          } else {
            dispatch({ type: 'CheckOutDialog', payload: true })
          }
        },
        disabled:  !GuestAppFeatureList?.CheckOut ,
      },
    },
    {
      Icon: MyReqIcon,
      Title: 'My Request',
      Info: (
        <>
          <span className="fw-medium m-r-4">{MyRequestCount} </span> Pending
          Request.{' '}
        </>
      ),
      props: {
        onClick: () => dispatch({ type: 'Drawer', payload: true }),
        disabled: (!GuestAppFeatureList?.MyRequest && !GuestAppFeatureList?.OnlineShop && !GuestAppFeatureList?.RoomService) ,
      },
    },
    {
      Icon: PackagesRedIcon,
      Title: 'Package Redemption',
      Info: (
        <>
          <span className="fw-medium m-r-4"> {TotalPax || 0}</span>
          Pax |{' '}
          <span className="fw-medium m-l-4 m-r-4">{TotalRedeemed || 0}</span>
          Redeemed{' '}
        </>
      ),
      props: {
        onClick: () =>
          history.push(SERVICE_PATH.PACKAGE_REDEMPTION, {
            Hotel: HotelDetails,
            Registration: RegistrationDetails,
          }),
        disabled: !GuestAppFeatureList?.PackageRedemption,
      },
    },
    {
      Icon: LateCheckoutIcon,
      Title: 'Late Checkout',
      Info: (
        <>
          <AccessTimeOutlinedIcon />
          <span className="fw-medium m-r-4"> {LateCheckOutTime}</span>
        </>
      ),
      props: {
        onClick: () =>
          history.push(SERVICE_PATH.LATE_CHECKOUT, {
            Hotel: HotelDetails,
            Registration: RegistrationDetails,
          }),
        disabled: !GuestAppFeatureList?.LateCheckOut,
      },
    },
    {
      Icon: ExtendStayIcon,
      Title: 'Extend Stay',
      Info: (
        <>
          <span className="fw-medium m-r-4">{CheckInDate}</span>
          to
          <span className="fw-medium m-l-4  m-r-4">{CheckOutDate}</span>
        </>
      ),
      props: {
        onClick: () =>
          history.push(SERVICE_PATH.EXTEND_STAY, {
            Hotel: HotelDetails,
            Registration: RegistrationDetails,
          }),
        disabled: !GuestAppFeatureList?.ExtendStay,
      },
    },
    {
      Icon: BillIcon,
      Title: 'Bills',
      Info: (
        <>
          <MonetizationOnIcon />
          <span className="fw-medium m-r-4">
            {' '}
            {amtStr(TotalOutStandingAmount)}
          </span>
        </>
      ),
      props: {
        onClick: () =>
          history.push(SERVICE_PATH.BILLS, {
            Hotel: HotelDetails,
            Registration: RegistrationDetails,
            TotalOutStandingAmount: TotalOutStandingAmount,
          }),
        disabled: !GuestAppFeatureList?.Bills,
      },
    },
    {
      Icon: CheckOutIcon,
      Title: 'Booking',
      Info: <>No Advance Booking.</>,
      props: {
        onClick: () => history.push(BOOKING_PATH.BOOKING_LIST_FROM_SERVICE),
        disabled: !GuestAppFeatureList?.Booking,
      },
    },
   
  ]
  const QRReader = (
    <Modal
      open={state.QRScannerModal}
      onClose={() => dispatch({ type: 'QRScannerModal', payload: false })}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <QrReader
        delay={300}
        onError={err => handleError(err)}
        onScan={data => handleScan(data)}
        className="qr-reader"
        style={{ width: '100%' }}
      /> 
    </Modal>
  )
  const Promo = GAMarketingAdsListing?.map(x => x.Gallery?.ImageURL)
  const MarketingAdsDialog = (
    <CommonDialog
      open={state.MarketingAdsDialog.Open}
      onClose={() => {
        dispatch({
          type: 'CloseMarketingAdsDialog',
          payload: {},
        })
      }}
      sections={{
        content: (
          <div className="room-info-wrapper">
            <Swiper
              pagination={true}
              className="promo-swiper"
              initialSlide={state.MarketingAdsIndex}
              onSlideChange={e =>
                dispatch({
                  type: 'MarketingAdsIndex',
                  payload: e.activeIndex,
                })
              }
            >
              {Promo.map((v: string, i: number) => (
                <SwiperSlide key={i}>
                  <img src={v} alt={v} />
                </SwiperSlide>
              ))}
            </Swiper>

            <div className="mdLabel text-noflow m-t-10">
              {GAMarketingAdsListing[state.MarketingAdsIndex]?.Description}
            </div>
          </div>
        ),
        footer: {
          children: (
            <div className="single-button">
              <Button
                variant="contained"
                color="primary"
                size="small"
                className="showmore-btn"
                onClick={() =>
                  history.push(SERVICE_PATH.MARKETING_ADS, {
                    ...GAMarketingAdsListing[state.MarketingAdsIndex],
                  })
                }
              >
                Show more
              </Button>
            </div>
          ),
        },
      }}
    />
  )

  
  return (
    <>
      <ContentWrapper>
        { (loading || loadingextend || loadinglatecheckout) && <Spinner />}
        <div className="room-info-wrapper">
          <Swiper pagination={true} className="promo-swiper" >
            {Promo.map((v: string, i: number) => (
              <SwiperSlide  style={{height:'150px'}} key={i}>
                <img
                  src={v}
                  alt={v}
                  style={{height:'150px'}}
                  onClick={() => {
                    dispatch({ type: 'AdsDialog', payload: {} })
                    dispatch({
                      type: 'MarketingAdsIndex',
                      payload: i,
                    })
                  }}
                />
              </SwiperSlide>
            ))}
          </Swiper>
    
        </div>

        <List className="core-list">
          {ServicesList?.map((el, i) => (
   
          el.props.disabled ? 
          ('') : (
             
             
            <ListItem
              key={i}
              {...el.props}
              onClick={el.props.disabled ? null : el.props.onClick}
            >
              <ListItemAvatar>
                <Avatar className="svg-icon" src={el.Icon} />
              </ListItemAvatar>

              <ListItemText
                primary={
                  <>
                    <span className="xsTitle fw-500 flex-space">
                      {el.Title}
                    </span>
                  </>
                }
                secondary={
                  <>
                    <span className="desc flex-space icon-text">{el.Info}</span>
                  </>
                }
              />
            </ListItem>
          
          )))
              
        }
        </List>
      </ContentWrapper>
    {GuestAppFeatureList?.DigitalKey ? (
      <Fab
      color="primary"
      aria-label="add"
      className="fab"
      onClick={() =>
         history.push(SERVICE_PATH.QRpasscode,{Passcode,RoomNo:RoomDetails?.RoomNo})
      }
    >
      <img 
      src={ScanIcon}
                    width={29}
                    height={29}
                    style={{paddingBottom:'15px'}}
                    srcSet={ScanIcon}
                    loading="lazy"
                    alt=""/><br/>
                    <span style={{fontSize:'7px', color:'white' ,position:'absolute', paddingTop:'25px'}}>Room {RoomDetails?.RoomNo}</span>
    </Fab>
    ) : ''}  
      {HeaderMenu}
      <CommonDialog
        open={state.Dialog.Open}
        onClose={() => dispatch({ type: 'CloseDialog', payload: {} })}
        sections={{
          content: (
            <>
              <div className="hotel-logo p-t-10 p-b-10">
                <Avatar
                  alt="companylogo"
                  src={HotelDetails?.LogoImage?.ImageURL || DefaultImg}
                  variant="circle"
                />
              </div>
              <div className="hotel-info m-t-8 m-b-8">
                <div className="icon-text desc fw-500 label">
                  <img src={AddressIcon} alt="phone" width={14} height={14} />{' '}
                  {HotelAddress}
                </div>
              </div>
              <div className="hotel-info m-t-8 m-b-16">
                <div className="icon-text desc fw-500 label">
                  <img src={PhoneNoIcon} alt="phone" width={14} height={14} />
                  {HotelDetails?.Contact?.PhoneNo}
                </div>
              </div>
              <Grid
                container
                justify="center"
                alignItems="center"
                className="help-info  m-b-8"
              >
                <Grid
                  item
                  xs={3}
                  className="align-center"
                  onClick={() =>
                    window.open(`https://waze.com/ul?q=${HotelAddress}`)
                  }
                >
                <WazeIcon/>
                </Grid>
                <Grid
                  item
                  xs={3}
                  className="align-center"
                  onClick={() =>
                    window.open(
                      `https://www.google.com/maps/dir/?api=1&origin=${HotelAddress}`
                    )
                  }
                >
                
                    <GoogleMapIcon style={{scale:'1'}}/>
                 
                </Grid>
                <Grid
                  item
                  xs={3}
                  className="align-center"
                  onClick={() =>
                    window.open(`tel:${HotelDetails?.Contact?.PhoneNo}`)
                  }
                >
                  <Avatar alt="companylogo" src={CallIcon} variant="rounded" />
                </Grid>
                <Grid
                  item
                  xs={3}
                  className="align-center"
                  onClick={() =>
                    window.open(
                      `mailto:${HotelDetails?.Contact?.BusinessEmail}`
                    )
                  }
                >
                  <Avatar alt="companylogo" src={MailIcon} variant="rounded" />
                </Grid>
              </Grid>
            </>
          ),
        }}
      />
   
      <CheckOutDialog
        state={state}
        dispatch={dispatch}
        RootState={RootState}
        RootDispatch={RootDispatch}
        HotelDetails={HotelDetails}
        RegistrationDetails={RegistrationDetails}
        RoomDetails={RoomDetails}
      />
      {QRReader}
      <RequestDrawer
        state={state}
        dispatch={dispatch}
        HotelDetails={HotelDetails}
        RegistrationDetails={RegistrationDetails}
        RoomDetails={RoomDetails}
        GuestAppFeatureList={GuestAppFeatureList}

      />
      {MarketingAdsDialog}
    </>
  )
}
