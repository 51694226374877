import React, { useEffect,useState } from "react";
import { ApolloClient, InMemoryCache, gql, ApolloProvider } from "@apollo/client";

let hotelxNodeUrl
switch (process.env.REACT_APP_API_URL) {
  case 'dev-build':
    hotelxNodeUrl = 'https://hotelx-dev-api.ifca.io/hotelx'
    break
  case 'prod-build':
    hotelxNodeUrl = 'https://hotelxapi.hotelx.asia/hotelx'
    break
  case 'uat-build':
    hotelxNodeUrl = 'https://hotelx-uat-api.ifca.io/hotelx'
    break
  default:
    hotelxNodeUrl = 'http://localhost:8000/hotelx'
    break
}

const CHECK_RECORD_QUERY = gql`
  query CheckRecord($Email: String, $NRIC: String, $Login: String, $Mode: String,$AccountID: String) {
    CheckRecord(Email: $Email, NRIC: $NRIC, Login: $Login,Mode: $Mode,AccountID: $AccountID)
    {
        isNRICExist
        isEmailExist
        isLoginIDExist
    }
  }
`;

const client = new ApolloClient({
    uri: hotelxNodeUrl, // Replace with your GraphQL API endpoint
    cache: new InMemoryCache()
  });

 export function  CheckRecord(Email,NRIC,Login,AccountID) {
    let emailresult = ''
    let nricresult = ''
    const [result, setresult] = useState({
        Email : '',
        NRIC : '',
        Login:'',
    })
  
   useEffect(() => {
      const checkRecord = async () => {
        try {
          const { data } = await client.query({
            query: CHECK_RECORD_QUERY,
            variables: { Email, NRIC, Mode:'',Login,AccountID } // Replace with the actual record ID you want to check
          });
          console.log(data,'data')
         
          
           setresult({
                Email : data?.CheckRecord?.isEmailExist,
                NRIC : data?.CheckRecord?.isNRICExist,
                Login :data?.CheckRecord?.isLoginIDExist,
            })

       
        } catch (error) {
            nricresult = "Error occurred while checking record:"
            emailresult = "Error occurred while checking record:"
           
        }

       return result ;
      };
  
      checkRecord();
      
    
    }, [Email,NRIC,Login]);
  
    return result
  }

  