import { ContentWrapper } from '@ifca-ui/neumorphism'
import { LinearProgress, useScrollTrigger } from '@material-ui/core'
import AppContext, { AppContextProps } from 'containers/Context/Context'
import { amtStr } from 'containers/Utils/numFormatter'
import { InvoiceTemplate } from 'containers/Utils/Template/Invoice'
import dateFormat from 'dateformat'
import {
  useFolioDetailsQuery,
  useHotelDetailsQuery,
  useLoggedInGuestProfileQuery,
  usePaymentDetailsLazyQuery,
} from 'generated/graphql'
import React, { useContext, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router'
import { BOOKING_PATH } from '../Booking/Routes'
import { SERVICE_PATH } from './Routes'
interface Address {
  AddressType?: string
  address?: string
  address2?: string
  city?: string
  country?: string
  postCode?: string
  siteName?: string
  state?: string
}
interface Props {
  window?: () => Window
  Mode?: string
}
export const InvoiceDocument = (props: Props) => {
  const { window, Mode } = props
  const { RootState, RootDispatch } = useContext<AppContextProps>(AppContext)
  const trigger = useScrollTrigger({
    target: window ? (window() as any) : undefined,
  })
  const history = useHistory()
  const location = useLocation()
  const localState = location.state as any
  const AddressConverter = (props: Address) => {
    const { address, address2, postCode, city, country, state } = props
    let FinalAddress = ''
    address && (FinalAddress += address + (address2 || postCode ? ', ' : ''))
    address2 && (FinalAddress += address2 + (postCode || city ? ', ' : ''))
    postCode && (FinalAddress += postCode + (city || state ? ', ' : ''))
    city && (FinalAddress += city + (state || country ? ', ' : ''))
    state && (FinalAddress += state + (country ? ', ' : ''))
    country && (FinalAddress += country + '. ')

    return FinalAddress
  }
  const {
    loading: infoLoading,
    data: { loggedInGuestProfile } = { loggedInGuestProfile: null },
  } = useLoggedInGuestProfileQuery({
    fetchPolicy: 'network-only',
  })
  const {
    loading,
    data: { FolioDetails } = { FolioDetails: null },
  } = useFolioDetailsQuery({
    variables: {
      ID: localState?.ID,
      HotelID: localState?.localState?.Hotel?.ID,
    },
    fetchPolicy: 'no-cache',
  })
  const [
    PaymentQuery,
    {
      data: { PaymentDetails } = { PaymentDetails: null },
      loading: PaymentLoading,
    },
  ] = usePaymentDetailsLazyQuery({
    fetchPolicy: 'no-cache',
  })
  const {
    loading: HotelInfoLoading,
    data: { HotelDetails } = { HotelDetails: null },
  } = useHotelDetailsQuery({
    variables: {
      HotelID: localState?.localState?.Hotel?.ID,
    },
    fetchPolicy: 'no-cache',
  })
  useEffect(() => {
    RootDispatch({
      type: 'HeaderSection',
      payload: {
        left: {
          icon: 'leftArrow',
          title: !trigger ? 'Invoice' : 'Receipt',
          props: {
            onClick: () =>
              history.push(
                Mode === 'Service'
                  ? SERVICE_PATH.BILLS
                  : BOOKING_PATH.BOOKING_DETAIL,
                { ...localState?.localState }
              ),
          },
        },
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger])

  useEffect(() => {
    if (FolioDetails) {
      PaymentQuery({
        variables: {
          ID: FolioDetails?.Payment && FolioDetails?.Payment[0]?.ID,
          HotelID: localState?.localState?.Hotel?.ID,
        },
      })
    }
  }, [FolioDetails])
  const SelectedItem = [FolioDetails?.BillLedger, FolioDetails?.RoomCharges]
    .filter(Boolean)
    .flat(10) as any
  const CheckArr = (Arr: any) => {
    var temp: any[] = []
    var Tax = false
    if (Arr?.BillLedger) {
      temp.length === 0
        ? (temp = Arr?.BillLedger)
        : (temp = [...temp, ...Arr?.BillLedger])
    }
    if (Arr?.DepositLedger) {
      temp.length === 0
        ? (temp = Arr?.DepositLedger)
        : (temp = [...temp, ...Arr?.DepositLedger])
    }
    if (Arr?.RoomLedger) {
      Tax = true
      temp.length === 0
        ? (temp = Arr?.RoomLedger)
        : (temp = [...temp, ...Arr?.RoomLedger])
    }
    return { temp, Tax }
  }
  let NewItems = CheckArr(FolioDetails)?.temp
  const SubTotal = SelectedItem?.reduce((acc, curr) => {
    return acc + curr.BaseAmount
  }, 0)
  var mapObj: any = {
    //Hotel
    '!!HotelImage!!': HotelDetails?.LogoImage?.ImageURL,
    '!!HotelName!!': HotelDetails?.HotelName,

    '!!HotelAddress1!!': !HotelDetails?.['HotelAddress']
      ? ''
      : !HotelDetails?.['HotelAddress']?.['address']
      ? ''
      : HotelDetails?.['HotelAddress']?.['address'] + ',',

    '!!HotelAddress2!!': !HotelDetails?.['HotelAddress']
      ? ''
      : (!HotelDetails?.['HotelAddress']?.['postCode']
          ? ''
          : HotelDetails?.['HotelAddress']?.['postCode'] + ', ') +
        (!HotelDetails?.['HotelAddress']?.['city']
          ? ''
          : HotelDetails?.['HotelAddress']?.['city'] + ', ') +
        (!HotelDetails?.['HotelAddress']?.['state']
          ? ''
          : HotelDetails?.['HotelAddress']?.['state']),
    //Header top
    '!!GuestName!!': loggedInGuestProfile?.Contact?.FullName,
    '!!GuestAddress1!!': AddressConverter({
      address: loggedInGuestProfile?.Contact?.Address?.address,
    }),
    '!!GuestAddress2!!': AddressConverter({
      postCode: loggedInGuestProfile?.Contact?.Address?.postCode,
      city: loggedInGuestProfile?.Contact?.Address?.city,
      state: loggedInGuestProfile?.Contact?.Address?.state,
    }),
    '!!InvoiceNo!!': FolioDetails?.FolioNo,
    '!!Date!!': dateFormat(FolioDetails?.FolioDate, 'dd mmm yyyy'),
    '!!Note!!': 'Invoice',

    //!description (new item)

    '!!NewItem!!': SelectedItem?.sort((a, b) => {
      return Number(new Date(a.TrxDate)) - Number(new Date(b.TrxDate))
    })?.map((x, index) => {
      return `  
      <tr 
      key="${index}"
      style="    
      color: black;
      font-size: 10px;
      line-height: 28px;
      background: transparent;
      text-align: left;
          ">
          <td style="padding-left: 10px; border-bottom: 1px solid #cccccc;">${
            index + 1
          }
          </td>
      <td style="padding-left: 10px; border-bottom: 1px solid #cccccc;">${
        x?.IncidentalCharge?.Description || x.Description
      }
      </td>
      <td style="text-align: right; padding-right: 10px; border-bottom: 1px solid #cccccc;">${amtStr(
        x?.BaseAmount || 0
      )}</td>
      <td style="text-align: right; padding-right: 10px; border-bottom: 1px solid #cccccc;">${amtStr(
        x?.TrxAmount || 0
      )}</td>
      </tr>
      `
    }),
    //tax count
    '!!Total!!': amtStr(FolioDetails?.FolioAmount),
    '!!TotalAmount!!': amtStr(FolioDetails?.FolioAmount),
  }
  var FormContent = InvoiceTemplate.replace(
    /!!Note!!|!!HotelImage!!|!!HotelName!!|!!HotelAddress1!!|!!HotelAddress2!!|!!GuestName!!|!!GuestAddress1!!|!!GuestAddress2!!|!!InvoiceNo!!|!!Date!!|!!NewItem!!|!!Total!!|!!TotalAmount!!/gi,
    function (matched) {
      return mapObj[matched]
    }
  )

  var ReceiptObj: any = {
    //Hotel
    '!!HotelImage!!': HotelDetails?.LogoImage?.ImageURL,
    '!!HotelName!!': HotelDetails?.HotelName,

    '!!HotelAddress1!!': !HotelDetails?.['HotelAddress']
      ? ''
      : !HotelDetails?.['HotelAddress']?.['address']
      ? ''
      : HotelDetails?.['HotelAddress']?.['address'] + ',',

    '!!HotelAddress2!!': !HotelDetails?.['HotelAddress']
      ? ''
      : (!HotelDetails?.['HotelAddress']?.['postCode']
          ? ''
          : HotelDetails?.['HotelAddress']?.['postCode'] + ', ') +
        (!HotelDetails?.['HotelAddress']?.['city']
          ? ''
          : HotelDetails?.['HotelAddress']?.['city'] + ', ') +
        (!HotelDetails?.['HotelAddress']?.['state']
          ? ''
          : HotelDetails?.['HotelAddress']?.['state']),
    //Header top
    '!!GuestName!!': loggedInGuestProfile?.Contact?.FullName,
    '!!GuestAddress1!!': AddressConverter({
      address: loggedInGuestProfile?.Contact?.Address?.address,
    }),
    '!!GuestAddress2!!': AddressConverter({
      postCode: loggedInGuestProfile?.Contact?.Address?.postCode,
      city: loggedInGuestProfile?.Contact?.Address?.city,
      state: loggedInGuestProfile?.Contact?.Address?.state,
    }),
    '!!InvoiceNo!!': PaymentDetails?.ReceiptNo,
    '!!Date!!': dateFormat(PaymentDetails?.PaymentDate, 'dd mmm yyyy'),
    '!!Note!!': 'Receipt',

    //!description (new item)

    '!!NewItem!!': `  
      <tr 
      style="    
      color: black;
      font-size: 10px;
      line-height: 28px;
      background: transparent;
      text-align: left;
          ">
          <td style="padding-left: 10px; border-bottom: 1px solid #cccccc;">${1}
          </td>
      <td style="padding-left: 10px; border-bottom: 1px solid #cccccc;">${
        PaymentDetails?.PaymentType
      }
      </td>
      <td style="text-align: right; padding-right: 10px; border-bottom: 1px solid #cccccc;"></td>
      <td style="text-align: right; padding-right: 10px; border-bottom: 1px solid #cccccc;">${amtStr(
        PaymentDetails?.Amount || 0
      )}</td>
      </tr>
      `,
    //tax count
    '!!Total!!': amtStr(PaymentDetails?.Amount),
    '!!TotalAmount!!': amtStr(FolioDetails?.FolioAmount),
  }
  var ReceiptContent = InvoiceTemplate.replace(
    /!!Note!!|!!HotelImage!!|!!HotelName!!|!!HotelAddress1!!|!!HotelAddress2!!|!!GuestName!!|!!GuestAddress1!!|!!GuestAddress2!!|!!InvoiceNo!!|!!Date!!|!!NewItem!!|!!Total!!|!!TotalAmount!!/gi,
    function (matched) {
      return ReceiptObj[matched]
    }
  )
  return (
    <>
      <ContentWrapper>
        {loading || HotelInfoLoading ? (
          <div>
            {' '}
            <LinearProgress />
          </div>
        ) : (
          <div
            className="border-box-shadow p-10"
            //   style={{ fontSize: 12, padding: 5, backgroundColor: 'white' }}
            dangerouslySetInnerHTML={{
              __html: FormContent,
            }}
          />
        )}
        <div className="m-b-30 m-t-30 b-b" />
        {PaymentLoading || HotelInfoLoading ? (
          <div>
            {' '}
            <LinearProgress />
          </div>
        ) : (
          <div
            className="border-box-shadow p-10"
            //   style={{ fontSize: 12, padding: 5, backgroundColor: 'white' }}
            dangerouslySetInnerHTML={{
              __html: ReceiptContent,
            }}
          />
        )}
      </ContentWrapper>
    </>
  )
}
