
import React, { FC, Reducer, useContext, useEffect, useReducer } from 'react'
export const Checkout = () => {
 
  return (
    <>
    
        <h4 style={{height: '100px',
   
     lineHeight: '40px',
     textAlign: 'center'}}>This room has been checkout</h4>
        
    </>
  )
}
