/* eslint-disable array-callback-return */
import { CommonDialog } from '@ifca-ui/neumorphism'
import { Grid } from '@material-ui/core'
import ChargeToRoomIcon from 'assets/svg/chargetoroom.svg'
import OnlinePaymentIcon from 'assets/svg/online-payment.svg'
import React from 'react'

export const PaymentDialog = ({
  state,
  dispatch,
  IsPaymentGateWayHotel,
  ChargeToRoom,
  OnlinePayment,
  ServicePaymentSetting
}) => {
  return (
    <CommonDialog
      open={state.PaymentDialog?.Open}
      onClose={() => {
        dispatch({
          type: 'ClosePaymentDialog',
          payload: {},
        })
      }}
      sections={{
        content: (
          <>
            {' '}
            <Grid
              className="m-t-15  text-align-center"
              container
              justify="flex-start"
              spacing={5}
            >
               { ServicePaymentSetting?.ServicePaymentSetting?.IsChargeToRoom ?
              <Grid
                item
                xs={IsPaymentGateWayHotel?.IsPaymentGateWayHotel &&
                   ServicePaymentSetting?.ServicePaymentSetting?.IsOnlinePayment ? 6 : 12}
                onClick={() => ChargeToRoom()}
              >
                <div className="request-section m-b-12">
                  <img
                    src={ChargeToRoomIcon}
                    width={45}
                    height={45}
                    loading="lazy"
                    alt=""
                  />
                </div>
                <div className="fs-12 fw-medium  color-text">
                  Charge to Room
                </div>
              </Grid>
              :""
      }
              {IsPaymentGateWayHotel?.IsPaymentGateWayHotel && ServicePaymentSetting?.ServicePaymentSetting?.IsOnlinePayment ? (
                <Grid item xs={6} onClick={() => OnlinePayment()}>
                  <div className="request-section m-b-12">
                    <img
                      src={OnlinePaymentIcon}
                      width={45}
                      height={45}
                      loading="lazy"
                      alt=""
                    />
                  </div>
                  <div className="fs-12 fw-medium  color-text">
                    Online Payment
                  </div>
                </Grid>
              ) : null}
            </Grid>
          </>
        ),
      }}
    />
  )
}
