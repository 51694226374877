import { ContentWrapper, Spinner, StyledFooter } from '@ifca-ui/neumorphism'
import { Badge, IconButton } from '@material-ui/core'
import { DatePicker } from '@material-ui/pickers'
import { RedirectUrl } from 'containers/App/Client'
import AppContext, { AppContextProps } from 'containers/Context/Context'
import { IAction } from 'containers/Context/Types'
import { DialogMode } from 'containers/Utils/CommonTypes'
import { amtStr } from 'containers/Utils/numFormatter'
import { addDays, format, isSameDay } from 'date-fns/esm'
import dateFormat from 'dateformat'
import {
  ChargeType,
  PaymentClass,
  PaymentStatus,
  RateCategory,
  TaxIdentifier,
  useAvailabilityListingQuery,
  useComputeTaxbyHotelLazyQuery,
  useExtendStayInsertMutation,
  useIsPaymentGateWayHotelQuery,
  useOnlinePaymentMutation,
  usePackagePriceListingQuery,
  useRegistrationListingQuery,
  useServicePaymentSettingQuery,
  // useIsHotelLockUserQuery,
} from 'generated/graphql'
import React, { FC, Reducer, useContext, useEffect, useReducer } from 'react'
import { useHistory, useLocation } from 'react-router'
import { PaymentDialog } from './PaymentDialog'
import { SERVICE_PATH } from './Routes'
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn'

interface Props {
  DateSelected?: any
  ArrivalDate?: Date
  DepartureDate?: Date
  PaymentDialog?: {
    Open?: boolean
    Mode?: DialogMode
  }
}
export const ExtendStay: FC = () => {
  const { RootState, RootDispatch } = useContext<AppContextProps>(AppContext)
  const history = useHistory()
  const location = useLocation()
  const localState = location.state as any
  const {
    data: IsPaymentGateWayHotel = { IsPaymentGateWayHotel: null },
  } = useIsPaymentGateWayHotelQuery({
    fetchPolicy: 'no-cache',
    variables: {
      HotelID: localState?.Hotel?.ID,
    },
  })
  const {
    data: { RegistrationListing } = { RegistrationListing: [] },
  } = useRegistrationListingQuery({
    variables: { RoomID: localState?.Registration.Room?.ID },
    fetchPolicy: 'no-cache',
  })

  // const {
  //   data: { IsHotelLockUser } = { IsHotelLockUser: false },
  // } = useIsHotelLockUserQuery({
  //   variables: { HotelID: localState?.Hotel?.ID },
  //   fetchPolicy: 'no-cache',
  // })

  const {
    data: ServicePaymentSetting = { ServicePaymentSetting: null },
  } = useServicePaymentSettingQuery({
    fetchPolicy: 'no-cache',
    variables: {
      HotelID: localState?.Hotel?.ID,
    },
  })

  const initialState: Props = {
    DateSelected: null,
    ArrivalDate: localState?.Registration?.ArrivalDate,
    DepartureDate: localState?.Registration?.DepartureDate,
    PaymentDialog: {
      Open: false,
      Mode: '',
    },
  }
  const reducer: Reducer<Props, IAction> = (state, action) => {
    switch (action.type) {
      case 'PaymentDialog':
        return { ...state, PaymentDialog: { Open: true, Mode: 'Payment' } }
      case 'ClosePaymentDialog':
        return { ...state, PaymentDialog: { Open: false, Mode: '' } }
      case 'reset':
        return initialState
      default:
        return { ...state, [action.type]: action.payload }
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState)
  const {
    data: { PackagePriceListing } = {
      PackagePriceListing: [],
    },
  } = usePackagePriceListingQuery({
    variables: {
      HotelID: localState?.Hotel?.ID,
      DebtorType: RateCategory.Public,
      RoomTypeID: localState?.Registration.RoomType?.ID,
      RatePolicyID: localState?.Registration.RatePolicy?.ID,
      StartDate: state.ArrivalDate,
      EndDate: addDays(new Date(state.DepartureDate), 1),
    },
    fetchPolicy: 'no-cache',
  })
  useEffect(() => {
    RootDispatch({
      type: 'HeaderSection',
      payload: {
        left: {
          icon: 'leftArrow',
          title: 'Extend Stay',
          props: {
            onClick: () => history.push(SERVICE_PATH.LIST),
          },
        },
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const {
    data: { AvailabilityListing: { RoomTypesList } } = {
      AvailabilityListing: { RoomTypesList: [] },
    },
  } = useAvailabilityListingQuery({
    variables: {
      HotelID: localState?.Hotel?.ID,
      StartDate: dateFormat(
        localState?.Registration?.DepartureDate,
        'yyyy-mm-dd'
      ),
      EndDate: dateFormat(state?.DepartureDate, 'yyyy-mm-dd'),
      RoomTypeIDs: localState?.Registration.RoomType?.ID,
    },
    fetchPolicy: 'no-cache',
  })

  let EmptyVacant = RoomTypesList[0]?.RoomTypeAvailability.filter(
    x => x.TotalVacant <= 0
  )?.map(y => y?.EffectiveDate)
  let DateArr = (RegistrationListing as any).map(
    x => x?.ArrivalDate || x.Booking?.ArrivalDate
  )

  let DisableDate = DateArr?.find(
    x =>
      dateFormat(x, 'yyyy mm dd') >
      dateFormat(localState?.Registration?.DepartureDate, 'yyyy mm dd')
  )
  let ExtendStayTotalAmount =
    PackagePriceListing[0]?.Pricing?.filter(
      x =>
        dateFormat(x.Date, 'yyyy mm dd') >=
          dateFormat(localState?.Registration?.DepartureDate, 'yyyy mm dd') &&
        dateFormat(x.Date, 'yyyy-mm-dd') !==
          dateFormat(state.DepartureDate, 'yyyy-mm-dd')
    ).reduce((acc, curr) => {
      return acc + curr.Price
    }, 0) || 0
  // useEffect(() => {
  //   if (
  //     state?.DepartureDate >= localState?.Registration?.DepartureDate &&
  //     ExtendStayTotalAmount === 0
  //   ) {
  //     RootDispatch({
  //       type: 'OnSnackBar',
  //       payload: {
  //         ...RootState.OnSnackBar,
  //         Open: true,
  //         Message: 'No Rate Available ',
  //       },
  //     })
  //   } else {
  //     RootDispatch({
  //       type: 'OnSnackBar',
  //       payload: {
  //         ...RootState.OnSnackBar,
  //         Open: false,
  //       },
  //     })
  //   }
  // }, [ExtendStayTotalAmount])
  const [
    ComputeTax,
    { data: { ComputeTaxbyHotel } = { ComputeTaxbyHotel: null } },
  ] = useComputeTaxbyHotelLazyQuery({
    fetchPolicy: 'no-cache',
  })
  useEffect(() => {
    if (ExtendStayTotalAmount) {
      ComputeTax({
        variables: {
          input: {
            HotelID: localState?.Hotel?.ID,
            TaxIdentifier: TaxIdentifier['Room'],
            Tax: true,
            ServiceCharge: true,
            TrxAmount: ExtendStayTotalAmount,
          },
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ExtendStayTotalAmount])
  const [OnlinePayment] = useOnlinePaymentMutation()

  const [ExtendStayInsert, { loading }] = useExtendStayInsertMutation({})
  const Variables = mode => {
    return {
      DepartureDate: state?.DepartureDate,
      ArrivalDate: state?.ArrivalDate,
      RoomTypeID: localState?.Registration?.RoomType?.ID,
      AppName:'loyalty-app',
      input: {
        BookingID: localState?.Registration?.Booking?.ID,
        HotelID: localState?.Hotel?.ID,
        PaymentType: mode,
        Amount: ComputeTaxbyHotel?.TotalAmount || 0,
        BaseAmount: ComputeTaxbyHotel?.BaseAmount || 0,
        ServiceCharge: ComputeTaxbyHotel?.ServiceCharge || 0,
        TaxAmount: ComputeTaxbyHotel?.TaxAmount || 0,
        ChargeType: ChargeType['ExtendStay'],
        PaymentClass: PaymentClass['Room'],
        RegistrationID: localState?.Registration?.ID,
        PaymentStatus: PaymentStatus['Pending'],
        AccountID: localState?.Hotel?.AccountID,
      },
    }
  }
  const handleChargeToRoom = () => {
    dispatch({ type: 'ClosePaymentDialog', payload: {} })

    ExtendStayInsert({
      variables: Variables('Room'),
    }).then(i => {
      if (i.data.ExtendStayInsert !== 'fail') {
        RootDispatch({
          type: 'OnSnackBar',
          payload: {
            ...RootState.OnSnackBar,
            Open: true,
            Message:'Extend Stay Successfully',
            //  IsHotelLockUser === true ? "Password validity extended succesfully" :
          },
        })
        dispatch({ type: 'ClosePaymentDialog', payload: {} })
        setTimeout(() => {
          RootDispatch({
            type: 'CloseSnackBar',
            payload: {},
          })
          history.push(SERVICE_PATH.LIST)
        }, 2000)
      } else {
        RootDispatch({
          type: 'OnSnackBar',
          payload: {
            ...RootState.OnSnackBar,
            Open: true,
            Message: 'Extend Stay Fail',
          },
        })
      }
    })
  }
  const handleOnlinePayment = () => {
    dispatch({ type: 'ClosePaymentDialog', payload: {} })

    // localStorage.setItem('isRoomService', 'true')
    // localStorage.setItem('FDReceiptNo', i.data.ExtendStayInsert.ReceiptNo        )
    //      localStorage.setItem('HotelID',localState?.Hotel?.ID)
    // localStorage.setItem(
    //   'CheckOutID',
    //   '0'
    // )
  
    OnlinePayment({
      variables: {
        redirectUrl: 
        `${
          window.location.hostname === 'localhost' ? 
        'http://localhost:3333' :
       'https://'+window.location.hostname}/services/redirect/:status`,
        PayAmount:
          process.env.REACT_APP_API_URL === 'prod-build' ||
          process.env.REACT_APP_API_URL === 'uat-build'
            ? Number(ComputeTaxbyHotel?.TotalAmount)
            : 1,
        Detail: `Late Checkout Payment`,
        Title: ` Late Checkout Payment`,
        HotelID: localState?.Hotel?.ID,

        ReceiptNo: 'extendstay',
      },
    }).then(i => {
      if (i.data.OnlinePayment?.code === 'SUCCESS') {

          localStorage.setItem(
          'CheckOutID',
          i.data.OnlinePayment.item.checkoutId
        )
        localStorage.setItem('HotelID', localState?.Hotel?.ID)
        localStorage.setItem('FDReceiptNo', '0')
        localStorage.setItem('isRoomService', 'true')
        console.log(Variables('Online'),'test')
        localStorage.setItem('extendstay',JSON.stringify(Variables('Online')))
        RootDispatch({
          type: 'OnSnackBar',
          payload: {
            ...RootState.OnSnackBar,
            Open: true,
            Message: 'Redirecting to payment gateway...',
          },
        })
        window.location.assign(i.data.OnlinePayment.item.url)
      } else {
        if(i.data.OnlinePayment.error.code !== '' || i.data.OnlinePayment.error.code !== null){
          RootDispatch({
              type: 'OnSnackBar',
              payload: {
                ...RootState.OnSnackBar,
                Open: true,
                Message: i.data.OnlinePayment.error.message,
              },
            })
          
            // window.location.replace(`${RedirectUrl}/services?status=CANCELLED&orderId=0`);
            // console.log(`${RedirectUrl}/services?status=CANCELLED&orderId=0`,'test', )
        }
         else { RootDispatch({
            type: 'OnSnackBar',
            payload: {
              ...RootState.OnSnackBar,
              Open: true,
              Message: 'Insert Fail.',
            },
          })}
      }
    })
  }

  return (
    <>
      {loading && <Spinner />}
      <ContentWrapper footer>
        <div className="text-align-center extend-stay-section">
          <div className="date-circle">
            <div>
              <div className="color-text d-date">
                {format(
                  new Date(localState?.Registration?.DepartureDate),
                  'dd'
                )}
              </div>
              <div className="color-text m-y-date">
                {format(
                  new Date(localState?.Registration?.DepartureDate),
                  'MMM yyyy'
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="text-align-center m-b-14">
          <div className=" fw-semibold fs-18">New Checkout Date</div>
        </div>
        <div className="extend-stay-date-picker">
          <DatePicker
            autoOk
            disableToolbar
            orientation="portrait"
            disablePast
            variant="static"
            openTo="date"
            value={state.DepartureDate}
            onChange={date => {
              dispatch({
                type: 'DepartureDate',
                payload: date,
              })
            }}
            leftArrowButtonProps={{
              className: 'left-button',
            }}
            minDate={
              new Date(localState?.Registration?.ArrivalDate) > new Date()
                ? new Date(localState?.Registration?.ArrivalDate)
                : new Date()
            }
            maxDate={
              EmptyVacant?.length > 0
                ? dateFormat(EmptyVacant[0], 'yyyy-mm-dd')
                : DisableDate === undefined
                ? undefined
                : dateFormat(DisableDate, 'yyyy-mm-dd')
            }
            renderDay={(day, selectedDate, isInCurrentMonth, dayComponent) => {
              return isSameDay(new Date(state.ArrivalDate), day) ? (
                <IconButton
                  // style={{ backgroundColor: 'red' }}
                  style={{
                    padding: '0px',
                  }}
                  className="circle-date-selected"
                >
                  {dayComponent}{' '}
                </IconButton>
              ) : (
                dayComponent
              )
            }}
          />
        </div>
        <div className="text-align-center ">
          <div className=" mdLabel  m-b-4 ">Total</div>
          <div
            className=" fs-25 fw-bold  m-b-4 color-blue"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <MonetizationOnIcon /> {amtStr(ExtendStayTotalAmount)}
          </div>
        </div>
      </ContentWrapper>
      {/* <StyledFooter
        sections={{
          option: [
            {
              props: {
                onClick: () => handleChargeToRoom(),
                disabled: ExtendStayTotalAmount === 0,
              },

              name: 'Charge to Room',
            },
            {
              props: {
                onClick: () => handleOnlinePayment(),
                disabled: !IsPaymentGateWayHotel || ExtendStayTotalAmount === 0,
              },

              name: 'Pay Now',
            },
          ],
        }}
      /> */}
      <StyledFooter
        sections={{
          isSingle: true,
          option: [
            {
              props: {
                onClick: () => dispatch({ type: 'PaymentDialog', payload: {} }),
                disabled: ExtendStayTotalAmount === 0,
              },
              name: 'Confirm',
            },
          ],
        }}
      />
      <PaymentDialog
        state={state}
        ServicePaymentSetting={ServicePaymentSetting}
        dispatch={dispatch}
        IsPaymentGateWayHotel={IsPaymentGateWayHotel}
        ChargeToRoom={() => handleChargeToRoom()}
        OnlinePayment={() => handleOnlinePayment()}
      />
    </>
  )
}
