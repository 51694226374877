export interface Address {
  AddressType?: string
  address?: string
  address2?: string
  city?: string
  country?: string
  postCode?: string
  siteName?: string
  state?: string
}
const AddressConverter = (props: Address) => {
  const {
    address,
    address2,
    postCode,
    city,
    country,
    siteName,
    state,
    AddressType,
  } = props
  let FinalAddress = ''
  address && (FinalAddress += address + (address2 || postCode ? ',\n ' : ''))
  address2 && (FinalAddress += address2 + (postCode || city ? ',\n ' : ''))
  postCode && (FinalAddress += postCode + (city || state ? ', ' : ''))
  city && (FinalAddress += city + (state || country ? ', ' : ''))
  state && (FinalAddress += state + (country ? ', ' : ''))
  country && (FinalAddress += country + '. ')

  return FinalAddress
}

export default AddressConverter
