import { yupResolver } from '@hookform/resolvers/yup'
import { AuthWrapper } from '@ifca-ui/neumorphism'
import {
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Box,
} from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import AppLogo from 'assets/images/hotelx.png'
import AuthBackground from 'assets/images/auth-background.jpg'
import AppContext, { AppContextProps } from 'containers/Context/Context'
import jwtDecode from 'jwt-decode'
// import AppContext, { AppContextProps } from "containers/Context/Context";
import React, { FC, useContext, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useHistory, useParams,useLocation } from 'react-router'
import * as yup from 'yup'
import {
  useGuestResetPasswordMutation,
  useSendRegisterOtpMutation,
  useVerifyOtpMutation,
} from '../../../generated/graphql'
import { PUBLIC_PATH } from './Routes'
import { useState } from 'react'
import PhoneInput from 'react-phone-input-2'
import { usePhoneValidation } from 'containers/hooks/usePhoneValidation'
import { OTPDialog } from './OTPDialog'

type RPProps = {
  NewPassword: string
  ConfirmPassword: string
  MobileNo?: string
  Email?:string
  ShowNewPassword?: boolean
  ShowConfirmPassword?: boolean
}


const ResetPasswordSchema = yup.object().shape({
  Email: yup.string(),
  NewPassword: yup
    .string()
    .required('New password is required')
    .min(8, 'Password must be at least 8 characters')
    .matches(
      /(?=.*[A-Z])/,
      'Password must contain at least one uppercase character'
    )
    .matches(
      /(?=.*[a-z])/,
      'Password must contain at least one lowercase character'
    )
    .matches(/(?=.*\d)/, 'Password must contain at least one number')
    .matches(
      /(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})/,
      'Password must contain at least one symbol'
    ),
  ConfirmPassword: yup
    .string()
    .required()
    .oneOf([yup.ref('NewPassword'), null], 'Confirm password does not match'),
})
export const ForgotPasswordRetrieved: FC = () => {
  const history = useHistory()
  const location = useLocation()
  const localState = location.state as any

  const { RootState, RootDispatch } = useContext<AppContextProps>(AppContext)
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
const token = params?.token
  // Get the token parameter from the URL
  const tokenInfo:any = jwtDecode(token)
  const { isValidPoneNumber, validatePhonePrefix } = usePhoneValidation()
  const [verified, Isverified] = useState(false)
  const {
    control,
    getValues,
    watch,
    setValue,
    formState: { errors },
  } = useForm<RPProps>({
    defaultValues: {},
    mode: 'onBlur',
    resolver: yupResolver(ResetPasswordSchema),
  })
  const [GuestResetPassword, { loading }] = useGuestResetPasswordMutation({
    variables: {
      password: watch('NewPassword'),
      Email: tokenInfo?.Email
      //   token: token,
    },
    onError: error => {
      // eslint-disable-next-line array-callback-return
      error.graphQLErrors.map(({ message }) => {
        RootDispatch({
          type: 'OnSnackBar',
          payload: {
            ...RootState.OnSnackBar,
            Open: true,
            Message: message,
            onClick: () =>
              RootDispatch({
                type: 'CloseSnackBar',
                payload: {},
              }),
          },
        })
      })
    },
    onCompleted: data => {
      if (data.GuestResetPassword) {
        RootDispatch({
          type: 'OnSnackBar',
          payload: {
            ...RootState.OnSnackBar,
            Open: true,
            Message: 'Password has been reset Successfully. Please Login Again',
            onClick: () =>
              RootDispatch({
                type: 'CloseSnackBar',
                payload: {},
              }),
          },
        })
        setTimeout(() => {
          RootDispatch({
            type: 'CloseSnackBar',
            payload: {},
          })
          history.push(PUBLIC_PATH.LOGIN)
        }, 2000)
      }
    },
  })
  useEffect(() => {
   
   if(tokenInfo){
    Isverified(true)
   }
  },[tokenInfo])

 
 

  return (
    <>
      {' '}
      <AuthWrapper
         bgImageURL={AuthBackground}
         appLogoURL={tokenInfo?.Gallery || AppLogo}
         title={tokenInfo?.GroupName|| ""}
        subTitle="Reset Password"
      >
        <div className="form-wrapper">

          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Password"
                        onClick={() =>
                          setValue(
                            'ShowNewPassword',
                            !getValues('ShowNewPassword')
                          )
                        }
                      >
                        {watch('ShowNewPassword') ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                helperText={errors?.NewPassword?.message}
                error={errors?.NewPassword ? true : false}
                autoComplete="off"
                fullWidth
                label="New Password"
                type={watch('ShowNewPassword') ? 'text' : 'password'}
              />
            )}
            name="NewPassword"
            control={control}
          />
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Password"
                        onClick={() =>
                          setValue(
                            'ShowConfirmPassword',
                            !getValues('ShowConfirmPassword')
                          )
                        }
                      >
                        {watch('ShowConfirmPassword') ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                helperText={errors?.ConfirmPassword?.message}
                error={errors?.ConfirmPassword ? true : false}
                autoComplete="off"
                fullWidth
                label="Repeat New Password"
                type={watch('ShowConfirmPassword') ? 'text' : 'password'}
              />
            )}
            name="ConfirmPassword"
            control={control}
          />

      
 
        </div>
        <div className="button-box">
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className="login-btn"
            fullWidth
            disabled={!verified || !watch('NewPassword') || errors?.ConfirmPassword ? true : false}
            onClick={() => GuestResetPassword()}
          >
            Save
          </Button>
        </div>
  
      </AuthWrapper>
    </>
  )
}
