import { ContentWrapper, SearchFilter, CommonDialog, } from '@ifca-ui/neumorphism'
import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Button,
} from '@material-ui/core'
import DoneAllOutlinedIcon from '@material-ui/icons/DoneAllOutlined'
import Rating from '@material-ui/lab/Rating'
import SkeletonList from 'components/Skeleton/SkeletonList'
import AppContext, { AppContextProps } from 'containers/Context/Context'
import { IAction } from 'containers/Context/Types'
import { useMenuOption } from 'containers/hooks/useMenuOption'
import { RatingAvg } from 'containers/Utils/RatingAvg'
import SwiperCore, { Pagination } from 'swiper/core'
import { Swiper, SwiperSlide } from 'swiper/react'
import { format } from 'date-fns'
import { promises } from 'dns'
import moment from 'moment'
import queryString from 'query-string'
import React, { FC, Reducer, useContext, useEffect, useReducer,useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useHistory, useLocation } from 'react-router'
import { DialogMode } from 'containers/Utils/CommonTypes'
import {
  useAdvancePaymentUpdateMutation,
  useGaInHouseGuestListingQuery,
  useGuestBookingListingQuery,
  useGaMarketingAdsListingLazyQuery,
} from '../../../generated/graphql'
import { AccountMenuOption } from '../Account/AccountMenuOption'
import { PUBLIC_PATH } from '../Auth/Routes'
import { SERVICE_PATH } from '../Services/Routes'
import { CheckInOutDate } from './CheckInOutDate'
import { FilterOptions } from './FilterOption'
import { BOOKING_PATH } from './Routes'

interface Props {
  Search?: string
  CheckInDate?: any
  CheckOutDate?: any
  Adults: number
  Rooms: number
  Focus: string
  AnchorEl: null | undefined | HTMLElement
  CheckInDateAnchorEl: null | undefined | HTMLElement
  GuestBookingList: any
  disabledPrevMonth?: boolean
  MarketingAdsDialog?: {
    Open: boolean
    Mode: DialogMode
  }
  MarketingAdsIndex?: number
}
let dateFormat = require("dateformat");
export const Booking: FC = () => {
  const history = useHistory()
  const location = useLocation()
const [geolocationobject, setgeolocationobject] = useState(null)
  const PaymentInfo = queryString.parse(location.search)
  const [isadvancepaymentran, setisadvancepaymentran] = useState(false)
console.log(geolocationobject,'geolocationobject')
  const { RootState, RootDispatch } = useContext<AppContextProps>(AppContext)
  console.log(location,'location')
  const { anchorEl, handleClose, handleClick } = useMenuOption()
  const {
    data: { GAInHouseGuestListing } = { GAInHouseGuestListing: [] },
  } = useGaInHouseGuestListingQuery({
    variables: {
      GuestID: JSON.parse(localStorage.getItem('GuestLoginInfo'))?.ID,
    },
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      if (
        location.pathname !== '/booking' &&
        data.GAInHouseGuestListing?.length > 0
      ) {
        return history.push(SERVICE_PATH.LIST)
      } else {
        console.log('')
      }
    },
  })
  const [
    MarketingAdsQuery,
    { data: { GAMarketingAdsListing } = { GAMarketingAdsListing: [] } },
  ] = useGaMarketingAdsListingLazyQuery({
    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
  
  
      MarketingAdsQuery({
        variables: {
          HotelID: null,
          AccountID:JSON.parse(localStorage.getItem('GuestLoginInfo'))?.AccountID,
          isLoyaltyApp:true // inhouse already
        },
      })
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const [AdvancePaymentUpdate] = useAdvancePaymentUpdateMutation({
    variables: {
      CheckOutID: localStorage.getItem('CheckOutID'),
      OrderID: PaymentInfo.orderId as string,
      ReceiptNo: localStorage.getItem('FDReceiptNo'),
      HotelID: localStorage.getItem('HotelID'),
      Status:
        (PaymentInfo.status as string)?.charAt(0).toUpperCase() +
        (PaymentInfo.status as string)?.slice(1).toLowerCase(),
      IsGuestApp: true,
      AppName:'loyalty-app',
    },
    onCompleted: data => {
      if (data.AdvancePaymentUpdate) {
        let Status = PaymentInfo.status === 'CANCELLED' ? 'Cancelled Successfully' : 
        PaymentInfo.status === 'EXPIRED' ? 'Session Expired' : 
        'Payment Successfully'
        RootDispatch({
          type: 'OnSnackBar',
          payload: {
            ...RootState.OnSnackBar,
            Open: true,
            Message: `${Status}`,
            onClick: () =>
              RootDispatch({
                type: 'CloseSnackBar',
                payload: {},
              }),
          },
        })
        localStorage.removeItem('FDReceiptNo')
        localStorage.removeItem('CheckOutID')
        localStorage.removeItem('HotelID')

       
      } else {
        RootDispatch({
          type: 'OnSnackBar',
          payload: {
            ...RootState.OnSnackBar,
            Open: true,
            Message: 'Payment Fail',
            onClick: () =>
              RootDispatch({
                type: 'CloseSnackBar',
                payload: {},
              }),
          },
        })
      }
    },
    onError: error => {
      error.graphQLErrors?.map(({ message }) => {
        RootDispatch({
          type: 'OnSnackBar',
          payload: {
            ...RootState.OnSnackBar,
            Open: true,
            Message: message,
            onClick: () =>
              RootDispatch({
                type: 'CloseSnackBar',
                payload: {},
              }),
          },
        })
        localStorage.removeItem('FDReceiptNo')
        localStorage.removeItem('CheckOutID')
        localStorage.removeItem('HotelID')
        setisadvancepaymentran(true)
      })
    },
  })
  useEffect(() => {
    if (PaymentInfo?.orderId && isadvancepaymentran === false) {
      AdvancePaymentUpdate()
    }
  }, [])
  const {
    fetchMore,
    loading,
    data: { GuestBookingListing } = { GuestBookingListing: [] },
  } = useGuestBookingListingQuery({
    variables: {
      offset: 0,
      limit: 10,
      // HotelID: localStorage.getItem('HotelID'),
      GuestID: JSON.parse(localStorage.getItem('GuestLoginInfo'))?.ID,
    },
    fetchPolicy: 'network-only',
    onError: error => {
      // eslint-disable-next-line array-callback-return
      error.graphQLErrors.map(({ message }) => {
        RootDispatch({
          type: 'OnSnackBar',
          payload: {
            ...RootState.OnSnackBar,
            Open: true,
            Message:
              message === "Session expired, please login again."
                ? 'Login session expired, Please login again.'
                : message,
            onClick: () =>
              RootDispatch({
                type: 'CloseSnackBar',
                payload: {},
              }),
          },
        })
        if (message === "Session expired, please login again.") {
          setTimeout(() => {
            RootDispatch({
              type: 'CloseSnackBar',
              payload: {},
            })
            history.push(PUBLIC_PATH.LOGIN)
            localStorage.clear()
          }, 2000)
        }
      })
    },
  })

  //  a promise that resolves with geo coordinates
const getPosition = (options) => {
 console.log(navigator.geolocation)
  return new Promise(function (resolve, reject) {
    
    navigator.geolocation.getCurrentPosition(resolve, reject, options);
  });
}

const gpsLocation:any = async () => {


try{
        // calling the promise and awaiting the position object
        const geolocationData:any = await getPosition({ enableHighAccuracy: true });

        // destructruing the coordinates from the object
        const {latitude: lat, longitude: lng} = geolocationData.coords;

        // creating promises for each api call
      
        // wating for the promises to be resolved in parallel (rather than one after another)

        const addressPromise = await fetch(` https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyBbPkG0hX9GDe3aAB5LxaQkPnuQ7KGDViI&latlng`).then(response => 
          response.json().then(data => ({
              data: data,
              status: response.status
          })
      ).then(res => {
        
          setgeolocationobject({
            addresspromise: res.data,  
            lat,
            lng,
            city: res.data.results[0]?.address_components.find(x => x.types[0] === "locality")?.long_name
          })
      }));
        const [address] = await Promise.all([addressPromise]);

        return address
      }
      catch(err){
        alert('Please turn on Location')
      }

}

useEffect(()=>{
  dispatch({ type: 'Search', payload: geolocationobject?.addresspromise?.results[0]?.formatted_address })
  
},[geolocationobject])

  const initialState: Props = {
    Search: '',
    CheckInDate: moment(),
    CheckOutDate: moment().add(1, 'days'),
    Rooms: 1,
    Adults: 2,
    Focus: 'startDate',
    AnchorEl: null,
    CheckInDateAnchorEl: null,
    GuestBookingList: [],
    disabledPrevMonth: true,
    MarketingAdsDialog: {
      Open: false,
      Mode: '',
    },
    MarketingAdsIndex: 0,
  }

  const reducer: Reducer<Props, IAction> = (state, action) => {
    switch (action.type) {
      case 'RoomsIncrement':
        return { ...state, Rooms: state.Rooms + 1 }
      case 'RoomsDecrement':
        return { ...state, Rooms: state.Rooms - 1 }

      case 'AdultsIncrement':
        return { ...state, Adults: state.Adults + 1 }
      case 'AdultsDecrement':
        return { ...state, Adults: state.Adults - 1 }
      case 'Reset':
        return initialState
        case 'AdsDialog':
          return { ...state, MarketingAdsDialog: { Open: true, Mode: 'View' } }
        case 'CloseMarketingAdsDialog':
          return { ...state, MarketingAdsDialog: { Open: false, Mode: '' } }
      default:
        return { ...state, [action.type]: action.payload }
    }
  }
  const [state, dispatch] = useReducer(reducer, initialState)
  const Open = Boolean(state.AnchorEl)
  const CheckInDateOpen = Boolean(state.CheckInDateAnchorEl)
  const ID = Open ? 'simple-popover' : undefined
  const DatesID = CheckInDateOpen ? 'CheckInDateOpen' : undefined
  useEffect(() => {
    if (location?.pathname === '/booking') {
      RootDispatch({
        type: 'HeaderSection',
        payload: {
          left: {
            icon: 'leftArrow',
            title: 'Booking',
            Datalogo : null,
            props: {
              onClick: () => history.push(SERVICE_PATH.LIST),
            },
          },
          right: {
            icon: 'more',
            props: {
              onClick: e => handleClick(e),
              'aria-controls': 'customized-menu',
            },
          },
        },
      })
    } else {
      RootDispatch({
        type: 'HeaderSection',
        payload: {
          left: {
            icon: 'hotellogo',
            title: JSON.parse(localStorage.getItem('GuestLoginInfo'))?.title,
            Datalogo : JSON.parse(localStorage.getItem('GuestLoginInfo'))?.appLogoURL,
            Isbutton:false,
            Loyaltyapp:true
            
          },
          right: {
            icon: 'more',
            props: {
              onClick: e => handleClick(e),
              'aria-controls': 'customized-menu',
            },
          },
        },
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])
  useEffect(() => {
    if (GuestBookingListing?.length > 0)
      dispatch({
        type: 'GuestBookingList',
        payload: GuestBookingListing,
      })
  }, [GuestBookingListing])
  
  // const [state, setState] = useState(Array.from({ length: 10 }));
  // const [hasMore, setHasMore] = useState(true);
  // const handleFetch = () => {
  //   if (state.length >= 500) {
  //     setHasMore(false);
  //   }
  //   setTimeout(() => {
  //     setState(state.concat(Array.from({ length: 10 })));
  //   }, 500);
  // };

  const HeaderMenu = (
    <AccountMenuOption anchorEl={anchorEl} handleClose={handleClose} />
  )
  const handleStatusDisplay = (Status, i) => {
    if (
      Status === 'CheckOut' &&
      i.Hotel?.HotelRating?.some(x => x.BookingID === i.ID)
    ) {
      return (
        <span className="icon-text  desc fw-600 ">
          <Rating
            defaultValue={RatingAvg(
              i.Hotel?.HotelRating?.find(x => x.BookingID === i.ID)
            )}
            size="small"
            name="rating"
          />
        </span>
      )
    }
    //  else if (Status === 'CheckOut') {
    //   return (
    //     <span className="icon-text color-blue-1 desc fw-600 ">
    //       {' '}
    //       <CheckOutGuest />
    //       Checked Out
    //     </span>
    //   )
    // }
    else if (Status === 'Inhouse') {
      return (
        <span className="icon-text color-tiffanyblue desc fw-600 ">
          {' '}
          <DoneAllOutlinedIcon />
          Checked In
        </span>
      )
    } else {
      return null
    }
  }
  const Promo = GAMarketingAdsListing?.map(x => x.Gallery?.ImageURL)
  const MarketingAdsDialog = (
    <CommonDialog
      open={state.MarketingAdsDialog.Open}
      onClose={() => {
        dispatch({
          type: 'CloseMarketingAdsDialog',
          payload: {},
        })
      }}
      sections={{
        content: (
          <div className="room-info-wrapper">
            <Swiper
              pagination={true}
              className="promo-swiper"
              initialSlide={state.MarketingAdsIndex}
              onSlideChange={e =>
                dispatch({
                  type: 'MarketingAdsIndex',
                  payload: e.activeIndex,
                })
              }
            >
              {Promo.map((v: string, i: number) => (
                <SwiperSlide key={i}>
                  <img src={v} alt={v} />
                </SwiperSlide>
              ))}
            </Swiper>

            <div className="mdLabel text-noflow m-t-10">
              {GAMarketingAdsListing[state.MarketingAdsIndex]?.Description}
            </div>
          </div>
        ),
        footer: {
          children: (
            <div className="single-button">
              <Button
                variant="contained"
                color="primary"
                size="small"
                className="showmore-btn"
                onClick={() =>
                  history.push(SERVICE_PATH.MARKETING_ADS, {
                    ...GAMarketingAdsListing[state.MarketingAdsIndex],
                  })
                }
              >
                Show more
              </Button>
            </div>
          ),
        },
      }}
    />
  )
  return (
    <>
      <ContentWrapper>
      <div className="room-info-wrapper">
          <Swiper pagination={true} className="promo-swiper" >
            {Promo.map((v: string, i: number) => (
              <SwiperSlide  style={{height:'150px'}} key={i}>
                <img
                  src={v}
                  alt={v}
                  style={{height:'150px'}}
                  onClick={() => {
                    dispatch({ type: 'AdsDialog', payload: {} })
                    dispatch({
                      type: 'MarketingAdsIndex',
                      payload: i,
                    })
                  }}
                />
              </SwiperSlide>
            ))}
          </Swiper>
    
        </div>
        <div>
          <div className="flex-width m-b-12">
            <span className="smTitle">Where are you going?</span>
          </div>
          <SearchFilter
            sections={{
              searchInput: {
                
                props: {
                  
                  value: state.Search  ,
                
                  onChange: e =>
                    dispatch({ type: 'Search', payload: e.target.value }),
                   
                  
                },
                iconOnClick:  () =>  gpsLocation() 
                
              },
              checkInDate: {
                value: state.CheckInDate?.format('D MMM YYYY'),
                props: {
                  onClick: event => {
                    dispatch({
                      type: 'CheckInDateAnchorEl',
                      payload: event.currentTarget,
                    })
                    dispatch({
                      type: 'Focus',
                      payload: 'startDate',
                    })
                  },
                  'aria-describedby': DatesID,
                },
              },
              checkOutDate: {
                value: state.CheckOutDate?.format('D MMM YYYY'),
                props: {
                  onClick: event => {
                    dispatch({
                      type: 'CheckInDateAnchorEl',
                      payload: event.currentTarget,
                    })
                    dispatch({
                      type: 'Focus',
                      payload: 'endDate',
                    })
                  },
                  'aria-describedby': DatesID,
                },
              },
              filter: {
                value: `${state.Adults} Adults`,
                props: {
                  onClick: event => {
                    dispatch({
                      type: 'AnchorEl',
                      payload: event.currentTarget,
                    })
                  },
                  'aria-describedby': ID,
                },
              },
              searchButton: {
                props: {
                  onClick: () =>
                    history.push(BOOKING_PATH.HOTEL_LIST, {
                      Search: geolocationobject?.addresspromise?.results[0]?.formatted_address !== undefined ? 
                      geolocationobject?.addresspromise?.results[0]?.formatted_address
                      : state.Search,
                      CheckInDate: dateFormat(state.CheckInDate,'yyyy-mm-dd'),
                      CheckOutDate: dateFormat(state.CheckOutDate,'yyyy-mm-dd'),
                      Rooms: state.Rooms,
                      Adults: state.Adults,
                      IsGrabLocation : geolocationobject?.addresspromise?.results[0]?.formatted_address !== undefined 
                      ? geolocationobject?.city : false
                    }),
                },
              },
         
            }}
          />
          

          <div className="flex-width m-b-12">
            <span className="smTitle">My Booking</span>
          </div>
        </div>
        <List className="core-list">
          <InfiniteScroll
            dataLength={GuestBookingListing?.length}
            next={() => {
              const currentLength = GuestBookingListing?.length
              fetchMore({
                variables: {
                  offset: currentLength,
                  limit: 10,
                },

                // updateQuery: (prev, { fetchMoreResult }) => {
                //   if (!fetchMoreResult) return prev
                //   console.log(prev, 'prev')
                //   console.log(fetchMoreResult, 'fetchMoreResult')

                //   return Object.assign({}, prev, {
                //     GuestBookingListing: [
                //       ...prev.GuestBookingListing,
                //       ...fetchMoreResult.GuestBookingListing,
                //     ],
                //   })
                // },
              }).then(fetchMoreResult => {
                // prev + fetch more result to our state
                dispatch({
                  type: 'GuestBookingList',
                  payload: [
                    ...state.GuestBookingList,
                    ...fetchMoreResult.data.GuestBookingListing,
                  ],
                })
              })
            }}
            hasMore={true}
            height={400}
            className="rm-padding"
            style={{ padding: '10px 12px' }}
            loader={loading && <SkeletonList type="Booking" />}
            endMessage={
              <p style={{ textAlign: 'center' }}>
                <b>Yay! You have seen it all</b>
              </p>
            }
          >
            {state.GuestBookingList?.map((i, index) => (
              <ListItem
                key={index}
                className={`${
                  (i.BookingStatus === 'CheckOut' ||
                    i.BookingStatus === 'Cancelled' ||
                    i.BookingStatus === 'NoShow') &&
                  'status'
                }`}
                onClick={() =>
                  history.push(BOOKING_PATH.BOOKING_DETAIL, { ...i })
                }
              >
                <ListItemAvatar>
                  <Avatar alt={`CH`} src={i.Hotel?.LogoImage?.ImageURL} />
                </ListItemAvatar>

                <ListItemText
                  primary={
                    <>
                      <span className="xsTitle fw-500 flex-space">
                        {i.BookingNo}{' '}
                        <span style={{ margin: '0px 4px' }}>|</span>{' '}
                        {i.Hotel?.HotelName}
                      </span>

                      {i.BookingStatus === 'CheckOut' &&
                      !i.Hotel?.HotelRating?.some(x => x.BookingID === i.ID) ? (
                        <span
                          className="fs-12 fw-regular color-blue primary-click-text"
                          style={{
                            color: '#2f5cff',
                          }}
                          onClick={event => {
                            event.stopPropagation()
                            history.push(SERVICE_PATH.RATING, {
                              ...i,
                            })
                          }}
                        >
                          Rate Stay{' '}
                        </span>
                      ) : null}
                    </>
                  }
                  secondary={
                    <>
                      <span className="desc flex-space">
                        {format(new Date(i.ArrivalDate), 'dd MMM yyyy')} -{' '}
                        {format(new Date(i.DepartureDate), 'dd MMM yyyy')}
                      </span>

                      {handleStatusDisplay(i.BookingStatus, i)}
                    </>
                  }
                />
              </ListItem>
            ))}
          </InfiniteScroll>
          {/* 
          <ListItem>
            <ListItemAvatar>
              <Avatar alt={`CH`} src={ifca} />
            </ListItemAvatar>

            <ListItemText
              primary={
                <>
                  <span className="xsTitle fw-500 flex-space">
                    IFCA Premier Hotel
                  </span>
                </>
              }
              secondary={
                <>
                  <span className="desc flex-space">
                    26 Dec 2020 - 29 dec 2020
                  </span>
                </>
              }
            />
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <Avatar alt={`CH`} src={ifca} />
            </ListItemAvatar>

            <ListItemText
              primary={
                <>
                  <span className="xsTitle fw-500 flex-space">
                    Mountain View Hotel
                  </span>
                </>
              }
              secondary={
                <>
                  <span className="desc flex-space">
                    26 Dec 2020 - 29 dec 2020
                  </span>
                  <span className="icon-text color-tiffanyblue desc fw-600 ">
                    {' '}
                    <DoneAllOutlinedIcon />
                    Checked In
                  </span>
                </>
              }
            />
          </ListItem>
       
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <Avatar alt={`CH`} src={ifca} />
              </Avatar>
            </ListItemAvatar>

            <ListItemText
              primary={
                <>
                  <span className="xsTitle fw-500 flex-space">Q - Hotel</span>
                </>
              }
              secondary={
                <>
                  <span className="desc flex-space">
                    26 Dec 2020 - 29 dec 2020
                  </span>
                  <span className="icon-text  desc fw-600 ">
                    <Rating defaultValue={5} size="small" name="rating" />
                  </span>
                </>
              }
            />
          </ListItem> */}
        </List>
      </ContentWrapper>
      <CheckInOutDate
        CheckInDateOpen={CheckInDateOpen}
        state={state}
        dispatch={dispatch}
      />
      <FilterOptions
        id={ID}
        open={Open}
        state={state}
        dispatch={dispatch}
        handleClose={() => dispatch({ type: 'AnchorEl', payload: null })}
      />
      {HeaderMenu}
      {MarketingAdsDialog}
    </>
  )
}
