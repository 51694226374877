import React, { useState } from 'react'
import { CommonDialog } from '@ifca-ui/neumorphism'
import { TextField, Button, Grid } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { amtStr } from 'containers/Utils/numFormatter'
import { Nationality } from 'generated/graphql'
/**
 *
 * @param Booking Summary and Booking Detail Sharing the same Dialog component
 * @returns
 */
export const BookingSummaryDialog = ({
  Mode,
  state,
  dispatch,
  LoggedGuest,
  TotalDiscountAmount,
  OnlineBookingInsert,
  handleGuestInsert,
  handleGuestContactUpdate,
  handleGuestProfileDelete,
  handleCancelBooking,
}: any) => {
  const NotLoggedInGuest = !(LoggedGuest?.ID === state.GuestInfo?.GuestID)
  let NationalityEnum: any = Object.values(Nationality).map(i => {
    return i.replace(/_/g, ' ')
  })
  const ContactNoValidation =
    LoggedGuest?.Contact?.MobileNo === state.GuestInfo?.MobileNo &&
    NotLoggedInGuest
  const EmailValidation =
    LoggedGuest?.Contact?.Email === state.GuestInfo?.Email && NotLoggedInGuest

  const handleConfirm = () => {
    const AddResults = state.RoomTypeSelected?.map((x: any, i: number) =>
      i === state.IndexSelected
        ? {
            ...x,
            Guests: [
              ...state.RoomTypeSelected[state.IndexSelected]?.Guests,
              state.GuestInfo,
            ],
          }
        : x
    )

    const UpdateResults = state.RoomTypeSelected?.map((x: any, i: number) =>
      i === state.IndexSelected
        ? {
            ...x,
            Guests: state.RoomTypeSelected[state.IndexSelected]?.Guests?.map(
              (el, i2) =>
                i2 === state.GuestSelected
                  ? {
                      ...state.GuestInfo,
                    }
                  : el
            ),
          }
        : x
    )
    dispatch({
      type: 'RoomTypeSelected',
      payload: state.Dialog?.Mode === 'Add' ? AddResults : UpdateResults,
    })
    dispatch({
      type: 'CloseDialog',
      payload: {},
    })
  }
  const handleSubmit = () => {
    dispatch({
      type: 'CloseDialog',
      payload: {},
    })
    if (Mode === 'Summary') {
      return state.Dialog.Mode === 'Payment'
        ? OnlineBookingInsert()
        : handleConfirm()
    }
    if (Mode === 'Detail') {
      return state.Dialog.Mode === 'Add'
        ? handleGuestInsert()
        : state.Dialog.Mode === 'Delete'
        ? handleGuestProfileDelete()
        : state.Dialog.Mode === 'Cancel'
        ? handleCancelBooking()
        : handleGuestContactUpdate()
    }
  }

  const [isClicked, setIsClicked] = useState(false);
  console.log(isClicked,'isClicked')
  const handleClick = () => {
    setIsClicked(true);
    handleSubmit()
  };
  return (
    <CommonDialog
      open={state.Dialog?.Open}
      onClose={() => {
        dispatch({
          type: 'CloseDialog',
          payload: {},
        })
      }}
      sections={{
        content: (
          <>
            <div className="m-b-8 title color-blue-2">
              {state.Dialog.Mode === 'Add'
                ? 'Add Guest'
                : state.Dialog.Mode === 'Edit'
                ? 'Edit Guest'
                : state.Dialog.Mode === 'Delete'
                ? 'Delete Guest'
                : state.Dialog.Mode === 'Cancel'
                ? 'Cancel Booking'
                : 'Payable Amount'}
            </div>

            {state.Dialog.Mode === 'Delete' ||
            state.Dialog.Mode === 'Cancel' ? (
              <div className="flex-width m-b-8  ">
                <span className="fs-14 fw-bold flex-space">
                  Are you sure you want to proceed?
                </span>
              </div>
            ) : state.Dialog.Mode === 'Payment' ? (
              <div className="flex-width m-b-8  ">
                <span className="fs-14 fw-bold flex-space">Amount</span>
                <span className="fs-14 fw-bold title">
                  $ {amtStr(TotalDiscountAmount)}
                </span>
              </div>
            ) : (
              <>
                <TextField
                  label="Name"
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  required
                  value={state?.GuestInfo?.FullName}
                  onChange={e =>
                    dispatch({
                      type: 'GuestInfo',
                      payload: {
                        ...state.GuestInfo,
                        FullName: e.target.value,
                      },
                    })
                  }
                />
                <Grid container justify="flex-start">
                  <Grid item xs={6} className="p-r-6">
                    <TextField
                      label="Contact No."
                      margin="normal"
                      error={ContactNoValidation}
                      helperText={
                        ContactNoValidation &&
                        'Please enter different contact no.'
                      }
                      type="tel"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                      required
                      value={state?.GuestInfo?.MobileNo}
                      onChange={e =>
                        dispatch({
                          type: 'GuestInfo',
                          payload: {
                            ...state.GuestInfo,
                            MobileNo: e.target.value,
                          },
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={6} className="p-l-6">
                    <Autocomplete
                      fullWidth
                      // className="left"
                      options={NationalityEnum}
                      getOptionLabel={option => option || ''}
                      value={state.GuestInfo.Nationality || ''}
                      onChange={(_, value) => {
                        dispatch({
                          type: 'GuestInfo',
                          payload: {
                            ...state.GuestInfo,
                            Nationality: value,
                          },
                        })
                      }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          required
                          label="Nationality"
                          margin="normal"
                          style={{ width: '100%' }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <TextField
                  label="Email Address"
                  margin="normal"
                  type="email"
                  fullWidth
                  error={EmailValidation}
                  helperText={
                    EmailValidation && 'Please enter different email address'
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={state?.GuestInfo?.Email}
                  onChange={e =>
                    dispatch({
                      type: 'GuestInfo',
                      payload: {
                        ...state.GuestInfo,
                        Email: e.target.value,
                      },
                    })
                  }
                />
              </>
            )}
          </>
        ),
        footer: {
          children: (
            <>
              <Button
                variant="contained"
                color="primary"
                size="small"
                className="action-btn"
                onClick={() =>
                  dispatch({
                    type: 'CloseDialog',
                    payload: {},
                  })
                }
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                size="small"
                className="action-btn"
                disabled={
                  isClicked === true ||(
                  state.Dialog.Mode === 'Payment' ||
                  state.Dialog.Mode === 'Delete' ||
                  state.Dialog.Mode === 'Cancel'
                    ? false
                    : ContactNoValidation ||
                      EmailValidation ||
                      !state.GuestInfo?.FullName ||
                      // !state.GuestInfo?.Email ||
                      !state.GuestInfo?.MobileNo ||
                      !state.GuestInfo?.Nationality
                  )
                }
                onClick={() => handleClick()}
              >
                Confirm
              </Button>
            </>
          ),
        },
      }}
    />
  )
}
