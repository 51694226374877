import {
  ContentWrapper,
  StyledCardContent,
  StyledFooter,
} from '@ifca-ui/neumorphism'
import {
  Checkbox,
  ListItem,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
  useTheme,
  SvgIcon,
} from '@material-ui/core'
import { ReactComponent as CheckBoxIcon } from 'assets/svg/CheckBoxIcon.svg'
import InvoiceIcon from 'assets/svg/invoice.svg'
import BillsIcon from 'assets/svg/service/bills.svg'
import { RedirectUrl } from 'containers/App/Client'
import AppContext, { AppContextProps } from 'containers/Context/Context'
import { IAction } from 'containers/Context/Types'
import { amtStr } from 'containers/Utils/numFormatter'
import dateFormat from 'dateformat'
import {
  PaymentClass,
  PaymentStatus,
  PaymentType,
  useBillingByRegistrationQuery,
  useFolioListingByRegistrationQuery,
  useFrontDeskPaymentInsertMutation,
  useIsPaymentGateWayHotelQuery,
  useOnlinePaymentMutation,
  usePaymentUpdateMutation,
} from 'generated/graphql'
import React, { FC, Reducer, useContext, useEffect, useReducer } from 'react'
import { useHistory, useLocation } from 'react-router'
import { SERVICE_PATH } from './Routes'
import queryString from 'query-string'
import { CheckOutDialog } from './CheckOutDialog'
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn'
import QRCode from 'qrcode.react'

interface Props {
  AdvancePaymentTotal: any
  PaymentSelected: any
  CheckOutDialog?: boolean
}
export const QRpasscode = () => {
  const { RootState, RootDispatch } = useContext<AppContextProps>(AppContext)
  const history = useHistory()
  const location = useLocation()
  const PaymentInfo = queryString.parse(location.search)

  const localState = location.state as any

  const initialState: Props = {
    PaymentSelected: [],
    AdvancePaymentTotal: 0,
    CheckOutDialog: false,
  }
  const reducer: Reducer<Props, IAction> = (state, action) => {
    switch (action.type) {
      case 'reset':
        return initialState
      default:
        return { ...state, [action.type]: action.payload }
    }
  }
  useEffect(() => {
    RootDispatch({
      type: 'HeaderSection',
      payload: {
        left: {
          icon: 'leftArrow',
          title: 'MY QR',
          props: {
            onClick: () => history.push(SERVICE_PATH.LIST),
          },
        },
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const [state, dispatch] = useReducer(reducer, initialState)
console.log(state,'state')
const theme = useTheme()
const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
  defaultMatches: true,
})
  return (
    <>
      <ContentWrapper footer>
      <div
              className="m-b-10"
              style={{
                display: 'flex',
                justifyContent: 'center',
                position:'relative'
              }}
            >
       <div  className=" text-align-center color-text mdDesc m-b-10">
       {localState?.Passcode ? localState?.Passcode?.keyboardPwd !== '' ?  'Passcode' : '' : ''}
            </div>
              
            </div>
            <div className="text-align-center  room-no shadow fs-30 fw-bold">
                  { localState?.Passcode ? localState?.Passcode?.keyboardPwd !== '' ? localState?.Passcode?.keyboardPwd+`#` : '' : ''}
                </div>
        <div style={{margin:'auto', width:'90%',maxWidth: isDesktop === false ? '90%' : '30%', marginTop: isDesktop === false ? '35%' : '5%',paddingBottom:'20px'}}>
        <StyledCardContent >
       
            <div  className=" text-align-center color-text mdDesc">
            <QRCode
            style={{height:'200px', width:'225px',padding:'15px',backgroundColor:'white',marginBottom:'10px',marginTop:'10px'}}
        renderAs={'canvas'}
        value={`${''}`}
      />
    <br/>
       <span className=" text-align-center color-text ">
              Scan Me
            </span>
        </div>
       
        </StyledCardContent>
        </div>
        <div style={{fontWeight:'600'}} className=" text-align-center color-text mdDesc m-b-10">
              This QR code is your guest identity
            </div>
  
            <div className=" text-align-center color-text-2 fs-12 fw-regular m-b-10">
            <span>Room Number :   &nbsp;</span>     <span style={{fontWeight:'750',fontSize:'16px'}} className=" text-align-center color-text mdDesc m-b-10">
             {localState?.RoomNo}
            </span>
            
            </div>
      </ContentWrapper>
   
   
    </>
  )
}
